import { useMemo } from 'react'
import zxcvbn from 'zxcvbn'
import { isEmpty } from 'lodash'
import { Progress, Tooltip, Whisper } from 'rsuite'

import { Info } from '../SvgIcon'
import './styles.css'

const PasswordStrengthMeter = ({ password }) => {
  // Use zxcvbn to evaluate the password strength
  const strength = useMemo(() => zxcvbn(password), [password])

  // Extract score and suggestions
  const { score } = strength

  // Calculate the progress percentage
  const progressPercent = (score / 4) * 100

  // Dynamically set the progress color based on the score
  const getProgressColor = () => {
    switch (score) {
      case 0:
        return '#828282' // Very weak
      case 1:
        return '#EA1111' // Weak
      case 2:
        return '#FFAD00' // Fair
      case 3:
        return '#9BC158' // Strong
      case 4:
        return '#00B500' // Very strong
      default:
        return '#828282' // Default to weak
    }
  }
  const getProgressLabel = () => {
    switch (score) {
      case 0:
        return 'Very Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Fair'
      case 3:
        return 'Strong'
      case 4:
        return 'Very Strong'
      default:
        return ''
    }
  }

  return (
    <div className="innerPasswordStrengthMeter">
      <Progress.Line
        className="passwordStrengthMeter"
        showInfo={false}
        strokeWidth={5}
        percent={progressPercent}
        strokeColor={getProgressColor()}
      />
      <div className="passwordStrengthText">
        <p style={{ color: getProgressColor() }}>{getProgressLabel()}</p>
        {!isEmpty(strength.feedback.warning) && (
          <Whisper
            placement="top"
            trigger="hover"
            speaker={
              <Tooltip>
                <p>{strength.feedback.warning}</p>
              </Tooltip>
            }
          >
            <div className="d-inline-block">
              <Info fill="var(--darkest-gray)" size={15} />
            </div>
          </Whisper>
        )}
      </div>
    </div>
  )
}

export default PasswordStrengthMeter
