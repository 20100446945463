import r from '../../utils/request'

export const updateThemeInfo = (params, toastMessages = {}) => {
  return r.putWithToast(`/admin/themes/${params.themeId}`, params, toastMessages)
}

export const importUsers = (params) => {
  return r.uploadWithToast(`/admin/themes/${params.themeId}/import/users`, params, 'put', {
    success: 'New users added successfully',
    error: 'Invalid file format. Please upload a valid file',
  })
}

export const importUsersPreview = (params) => {
  return r.uploadWithToast(`/admin/themes/${params.themeId}/import/users/preview`, params, 'put', {
    success: 'File is valid',
    error: 'Invalid file format. Please upload a valid file',
  })
}

export const getSampleImportUsersFile = () => {
  return r.downloadFile('/admin/themes/import/users/sample', 'get', 'SampleUsersImport.xlsx')
}

export const getThemeTags = (themeId) => {
  return r.get(`/admin/themes/${themeId}/tags`)
}

export const changeThemeTags = (params) => {
  return r.put(`/admin/themes/${params.id}/tags/sync`, params)
}
