import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { cloneDeep, get, isFunction, merge, set } from 'lodash'

import { convertStringToRegex } from '../../utils/helpers'
import ControllerField from './components/ControllerField'

const HookFormFieldsArray = (
  {
    className = '',
    onSubmit,
    Footer,
    defaultFields,
    focusField,
    globalName,
    globalFormClassName = '',
    appendComponent = () => {},
    prependComponent = () => {},
    defaultValues = {},
    hookFormSettings,
    ...rest
  },
  ref
) => {
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    setError,
    formState,
    setFocus,
    getValues,
    resetField,
  } = useForm({
    defaultValues: {
      ...defaultValues,
      [globalName]: defaultFields,
    },
    ...hookFormSettings,
  })

  const { fields, ...fieldArrayMethods } = useFieldArray({
    name: globalName,
    control,
  })
  const updateField = useCallback(
    (index, newValue) => {
      if (index >= 0 && index < fields.length) {
        const currentField = getValues(`${globalName}.${index}`)
        const updatedField = merge({}, currentField, newValue)

        fieldArrayMethods.update(index, updatedField)
      } else {
        console.warn(`Index ${index} is out of bounds.`)
      }
    },
    [getValues, fields]
  )

  useEffect(() => {
    if (focusField) {
      setFocus(focusField)
    }
  }, [setFocus, focusField])

  const processedFields = useMemo(() => {
    const clonedFields = cloneDeep(fields) // Deep copy of fields

    return clonedFields.map((field) => {
      const patternValue = get(field, 'rules.pattern.value')

      if (patternValue) {
        set(field, 'rules.pattern.value', convertStringToRegex(patternValue))
      }

      return field
    })
  }, [fields])
  const submitHandler = handleSubmit(onSubmit)

  React.useImperativeHandle(ref, () => {
    return {
      updateField,
      getValues,
      watch: watch,
      reset: reset,
      setError: setError,
      dirtyFields: formState.dirtyFields,
      setValue: setValue,
      handleSubmit: handleSubmit,
      formState: formState,
      resetField: resetField,
      submitHandler,
      setFocus,
      ...fieldArrayMethods,
    }
  })

  return (
    <form className={globalFormClassName} onSubmit={submitHandler} {...rest}>
      {prependComponent({ watch, control })}
      <div className={className}>
        {processedFields.map(({ name, rules, ...rest }) => (
          <ControllerField key={name} name={name} control={control} rules={rules} {...rest} />
        ))}
        {isFunction(Footer) ? (
          <Footer onSubmit={submitHandler} formState={formState} getValues={getValues} />
        ) : (
          Footer
        )}
      </div>
      {appendComponent({ watch, control })}
    </form>
  )
}

export default forwardRef(HookFormFieldsArray)
