import { useCallback, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'

import { FORM_CHANGE_USER_DATA } from '../../../../utils/constantsForms'
import { changePhoneNumber, updateUserInfoWithToast } from '../../../../api/user'
import { setNewDataUser } from '../../../../redux/actions/user'
import { formatPhoneNumber, isNullOrEmpty } from '../../../../utils/helpers'
import HookForm from '../../../../components/HookForm'
import PrimaryButton from '../../../../components/PrimaryButton'
import ModalOtpVerification from '../../../../components/Modals/ModalOtpVerification'

const TabDetails = ({ isActiveBlock, userData, setNewDataUser }) => {
  const [showModalConfirmNewPhone, setShowModalConfirmNewPhone] = useState(false)
  const formRef = useRef(null)
  const [, setSearchParams] = useSearchParams()

  const defaultFormValues = useMemo(
    () => ({
      timezone: {
        timezoneOffset: userData?.timezoneOffset,
        title: userData?.timezoneName,
      },
      phone: userData?.phone,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      gender: userData?.gender,
      familyStatus: userData?.familyStatus,
      birthdate: userData?.birthdate,
    }),
    []
  )

  const mutationUpdateUserDetails = useMutation({
    mutationKey: ['updateUserDetails', userData?.id],
    mutationFn: (params) => updateUserInfoWithToast(params, { success: 'User info saved' }),
    onSuccess: ({ data: userData }) => {
      setNewDataUser(userData)
      formRef.current.reset({}, { keepValues: true })
    },
  })

  const mutationSentConfirmationCode = useMutation({
    mutationKey: ['sentConfirmationCode'],
    mutationFn: changePhoneNumber,
    onSuccess: (_, { phone }) => {
      setShowModalConfirmNewPhone(true)
      setSearchParams({ phone })
    },
  })

  const handleSubmit = (formData) => {
    const { timezone, tags, gender, familyStatus } = formData
    const filteredData = omitBy(
      {
        ...formData,
        interests: tags,
        timezoneOffset: timezone?.timezoneOffset,
        timezoneName: timezone?.name,
        gender: gender?.title,
        familyStatus: familyStatus?.title,
      },
      isNullOrEmpty
    )
    const objKeys = Object.keys(formRef.current.dirtyFields)

    if (!(objKeys.includes('phone') && objKeys.length === 1)) {
      mutationUpdateUserDetails.mutate(filteredData)
    }

    if (formRef.current.dirtyFields.phone && formatPhoneNumber(userData.phone) !== formData.phone) {
      mutationSentConfirmationCode.mutate({ phone: formData.phone })
    }
  }

  const Footer = useCallback(({ formState }) => {
    return (
      <div className="footer">
        <PrimaryButton
          customClass="big"
          disabled={!formState.isValid || !formState.isDirty || mutationUpdateUserDetails.isPending}
          text="Save"
        />
      </div>
    )
  }, [])

  return (
    <>
      <HookForm
        className={`tabBlock formDetails ${isActiveBlock ? 'visibleTabBlock' : ''}`}
        defaultValues={defaultFormValues}
        ref={formRef}
        fields={FORM_CHANGE_USER_DATA}
        Footer={Footer}
        onSubmit={handleSubmit}
      />
      <ModalOtpVerification
        show={showModalConfirmNewPhone}
        onHide={() => {
          setSearchParams({})
          setShowModalConfirmNewPhone(false)
        }}
        onSuccess={() => formRef.current.reset({}, { keepValues: true })}
      />
    </>
  )
}

const mapStateToProps = ({ user }) => ({ userData: user.data })
const mapDispatchToProps = { setNewDataUser }
export default connect(mapStateToProps, mapDispatchToProps)(TabDetails)
