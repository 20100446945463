import { isNil } from 'lodash'
import { Button, Tooltip, Whisper } from 'rsuite'
import { forwardRef } from 'react'

const IconButton = (
  {
    children,
    onClick,
    buttonClassName,
    renderTooltipContent = () => null,
    show = true,
    id = 'button',
    placement = 'top',
    type = 'submit',
    triggerTooltip = 'hover',
    disabledButton = false,
    disabledTooltip = false,
    ...props
  },
  ref
) => {
  if (!show) {
    return null
  }

  return (
    <Whisper
      placement={placement}
      controlId={id}
      trigger={triggerTooltip}
      disabled={disabledTooltip || isNil(renderTooltipContent())}
      speaker={<Tooltip>{renderTooltipContent()}</Tooltip>}
    >
      <Button
        id={id}
        onClick={onClick}
        disabled={disabledButton}
        type={type}
        className={buttonClassName}
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    </Whisper>
  )
}

export default forwardRef(IconButton)
