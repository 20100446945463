import { useCallback } from 'react'
import { connect } from 'react-redux'
import { useMutation } from '@tanstack/react-query'

import { ROLES } from '../../../utils/constants'
import { switchAccountCompany } from '../../../api/admin/company'
import { updateToken } from '../../../utils/auth'
import { formatPhoneNumber } from '../../../utils/helpers'
import ImageLoader from '../../../components/ImageLoader'

const OrgCard = ({ name, logo, userRole, companyId, isCurrent, twilioPhoneNumber }) => {
  const mutationSwitchAdminCompany = useMutation({
    mutationKey: ['switchAdminCompany'],
    mutationFn: switchAccountCompany,
    onSuccess: async ({ data }) => {
      await updateToken(data?.newToken)
      window.location.reload()
    },
  })

  const handleClick = useCallback(() => {
    if (isCurrent) return
    if (userRole === ROLES.ROLE_ADMIN) {
      return mutationSwitchAdminCompany.mutate(companyId)
    }
  }, [companyId])

  return (
    <div onClick={handleClick} className={`orgCard ${isCurrent ? 'active' : ''}`}>
      <ImageLoader
        alt={`Logo ${name} organization`}
        loadingProps={{ size: 15 }}
        imageClassName="orgLogo"
        src={logo}
      />
      <div className="orgInfo">
        <p className="orgName">{name}</p>
        <p className="orgDescription">{formatPhoneNumber(twilioPhoneNumber) ?? '---'}</p>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  userRole: user.role,
})

export default connect(mapStateToProps)(OrgCard)
