import { useMemo } from 'react'
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dropdown } from 'rsuite'

import { routes } from '../../../router/routes'
import { displayAdminMenu, setShowEditorGlobalOnboardingSMS } from '../../../redux/actions/ui'
import { FillSetting, MessageSquareEdit, Messenger } from '../../SvgIcon'
import IconButton from '../../IconButton'
import BackButton from '../../BackButton'

const Header = ({ numberUnreadMessages, displayAdminMenu, setShowEditorGlobalOnboardingSMS }) => {
  const navigation = useNavigate()
  const { pathname } = useLocation()

  const isBackButtonVisible = useMemo(
    () =>
      [
        routes.programs,
        routes.programThemes,
        routes.theme,
        routes.themeSettings,
        routes.users,
        routes.user,
        routes.userThemeAnalytic,
      ].some((route) => matchPath(route, pathname)),
    [pathname]
  )

  return (
    <div className="adminHeaderInfoSection cursorDefault">
      <BackButton
        disabled={!isBackButtonVisible}
        className={`me-auto ${isBackButtonVisible ? 'opacity-100' : 'visibilityHidden opacity-0'}`}
      />

      <NavLink onClick={() => displayAdminMenu(false)} to={routes.messaging}>
        <IconButton
          aria-checked={pathname === routes.messaging}
          renderTooltipContent={() => <p>Chats</p>}
          placement="autoVerticalEnd"
          buttonClassName="btnAdminHeaderInfoSection message"
        >
          <Messenger size={20} fill="#969898" />
          {numberUnreadMessages >= 1 && <span className="indicator" />}
        </IconButton>
      </NavLink>
      <Dropdown
        renderToggle={(props, ref) => (
          <IconButton
            aria-checked={pathname === routes.accountSettings}
            renderTooltipContent={() => <p>Settings</p>}
            placement="autoVerticalEnd"
            buttonClassName="btnAdminHeaderInfoSection"
            ref={ref}
            {...props}
          >
            <FillSetting size={22} fill="#969898" />
          </IconButton>
        )}
        placement="bottomEnd"
      >
        <Dropdown.Item
          onClick={() => navigation(routes.accountSettings)}
          className="d-flex align-items-center gap-2"
          icon={<FillSetting size={17} fill="#969898" />}
        >
          Company Settings
        </Dropdown.Item>
        <Dropdown.Item
          className="d-flex align-items-center gap-2"
          onClick={() => setShowEditorGlobalOnboardingSMS(true)}
          icon={<MessageSquareEdit size={17} fill="#969898" />}
        >
          Onboarding SMS
        </Dropdown.Item>
      </Dropdown>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({ numberUnreadMessages: user.data.numberUnreadMessages })
const mapDispatchToProps = {
  setShowEditorGlobalOnboardingSMS,
  displayAdminMenu,
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
