import { isEmpty } from 'lodash'
import { Tooltip, Whisper } from 'rsuite'

import { Info } from './SvgIcon'
import CustomCheckbox from './CustomCheckbox'

const CheckboxList = ({
  innerClassName = '',
  error,
  value,
  label,
  showIconInfo,
  infoText,
  onChange,
  checkboxList = [],
  checkboxClassName = '',
  checkboxListClassName = '',
}) => {
  return (
    <div className={innerClassName}>
      {!isEmpty(label) && (
        <div className="d-flex align-items-center gap-1 inputLabel">
          <label>{label}</label>
          {showIconInfo && (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>
                  <p>{infoText}</p>
                </Tooltip>
              }
            >
              <span className="d-flex">
                <Info fill="var(--darkest-gray)" size={15} />
              </span>
            </Whisper>
          )}
        </div>
      )}
      <div className={checkboxListClassName}>
        {checkboxList.map((checkbox) => (
          <CustomCheckbox
            key={checkbox.value}
            className={checkboxClassName}
            name={checkbox.label}
            error={error}
            onChange={(checked) =>
              onChange(
                checked
                  ? [...value, checkbox.value]
                  : value.filter((item) => item !== checkbox.value)
              )
            }
            {...checkbox}
            value={value.includes(checkbox.value)}
          />
        ))}
      </div>
    </div>
  )
}

export default CheckboxList
