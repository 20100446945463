import { NavLink } from 'react-router-dom'
import { ExternalLinkAlt, WarningAmber } from '../../SvgIcon'
import { routes } from '../../../router/routes'

const IncompleteSetupBanner = ({ show }) => {
  if (!show) {
    return null
  }

  return (
    <div className="incompleteSetupBanner">
      <p className="attentionText fw-bold d-flex align-items-center gap-1">
        <WarningAmber size={20} fill="#FFFFFF" />
        Attention
      </p>
      <p className="attentionText flex-grow-1 text-center">
        Complete your organization setup to start creating programs.{' '}
        <NavLink
          className="fw-bold attentionText link d-inline-flex align-items-center gap-1"
          to={routes.accountSettings}
        >
          Click here to proceed <ExternalLinkAlt size={15} />
        </NavLink>
      </p>
    </div>
  )
}

export default IncompleteSetupBanner
