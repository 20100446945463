import { useMemo } from 'react'
import { connect } from 'react-redux'

import Page404 from '../pages/Page404'

const RenderPages = ({ Page, userData, hasAccess = () => true, currentCompany }) => {
  const userHasPermission = useMemo(
    () => hasAccess?.({ userData, currentCompany }),
    [userData, currentCompany, hasAccess]
  )

  return userHasPermission ? <Page /> : <Page404 />
}

export default connect(({ user, currentCompany }) => ({
  userData: user.data,
  currentCompany: currentCompany,
}))(RenderPages)
