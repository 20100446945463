import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { ROLES } from '../../constants'
import { FORM_LOGIN_ADMIN, OTP_FIELD } from '../../constantsForms'
import { routes } from '../../../router/routes'
import { useWebsocketContext } from '../../../components/SocketProvider'
import { confirmAdminLogin, getMe, loginAdmin } from '../../../api/auth'
import { updateToken } from '../../auth'
import { setNewDataUser, setRoleUser } from '../../../redux/actions/user'
import useWebSocket from '../useWebSocket'
import { getInfoCurrentCompany, getMyCompanies } from '../../../api/admin/company'
import { setCurrentCompany } from '../../../redux/actions/currentCompany'

const useAdminLogin = () => {
  const [isFirstStep, setIsFirstStep] = useState(true)
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { connect } = useWebSocket()
  const { handleWebSocketSubscription } = useWebsocketContext()

  const mutationGetAdminCompanies = useMutation({
    mutationKey: ['adminCompanies'],
    mutationFn: getMyCompanies,
    onSuccess: ({ data: companies }) => {
      return companies?.length > 1
        ? navigate(routes.adminSwitchAccount)
        : navigate(routes.dashboard)
    },
  })

  const authAdmin = async ({ data }) => {
    await updateToken(data.token)
    await connect()
    await handleWebSocketSubscription()
    const { data: adminInfo } = await getMe()

    const { data: companyData } = await getInfoCurrentCompany()
    dispatch(setRoleUser(ROLES.ROLE_ADMIN))
    dispatch(setNewDataUser(adminInfo.data))
    dispatch(setCurrentCompany(companyData))
    mutationGetAdminCompanies.mutate()
  }

  const resendCode = () => {
    const params = formRef.current?.getValues()

    return loginAdmin(params).catch(() => {
      formRef.current?.setError('password', {})
    })
  }

  const handleAdminLoginSendCode = (data) => {
    return loginAdmin(data)
      .then(() => {
        formRef.current.update(0, { ...FORM_LOGIN_ADMIN[0], innerClassName: 'hidden' })
        formRef.current.update(1, { ...FORM_LOGIN_ADMIN[1], innerClassName: 'hidden' })
        formRef.current.append({
          ...OTP_FIELD,
          onSuccess: () => formRef.current?.handleSubmit(handleConfirmLogin)(),
        })

        setIsFirstStep(false)
      })
      .catch(() => {
        formRef.current?.setError('password', {})
      })
  }

  const handleConfirmLogin = (data) => {
    return confirmAdminLogin(data)
      .then(authAdmin)
      .catch(() => {
        formRef.current?.setError('code')
      })
  }

  const handleAdminLogin = (data) => {
    if (isFirstStep) {
      return handleAdminLoginSendCode(data)
    }
    return handleConfirmLogin(data)
  }

  return {
    formRef,
    handleAdminLogin,
    buttonText: isFirstStep ? 'Login' : 'Confirm',
    resendCode,
    isFirstStep,
  }
}

export default useAdminLogin
