import r from '../../utils/request'

export const getMyCompanies = () => {
  return r.get('/admin/companies/my')
}

export const getInfoCurrentCompany = () => {
  return r.get('/admin/companies/current')
}

export const changeInfoCurrentCompany = (params) => {
  return r.upload('/admin/companies/current', params, 'put')
}

export const switchAccountCompany = (companyId) => {
  return r.post('/admin/companies/switch', { newCompanyId: companyId })
}

export const removeCurrentCompany = (params) => {
  return r.deleteWithToast('/admin/companies/current', null, {
    pending: 'Removing the company...',
    success: `Company "${params?.name}" removed successfully.`,
    error: 'Failed to remove the company.',
  })
}
