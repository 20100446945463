import { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { routes } from '../../../../router/routes'
import { SHOW_USER_MENU } from '../../../../utils/constants'
import { toggleDisplayUserMenu } from '../../../../redux/actions/ui'
import localStorage from '../../../../utils/localStorage'
import { IconProfile } from '../../../SvgIcon'
import OrganizationSwitcher from '../../../OrganizationSwitcher'

import './styles.css'

const Header = ({ firstName, lastName, showMenu, toggleDisplayUserMenu }) => {
  const userName = useMemo(() => `${firstName || ''} ${lastName || ''}`, [firstName, lastName])

  const toggleMenu = useCallback(() => {
    toggleDisplayUserMenu()
    localStorage.setItem(SHOW_USER_MENU, !showMenu)
  }, [showMenu])

  return (
    <header className="header">
      <button onClick={toggleMenu} className="btnToggleMenu">
        <span />
        <span />
        <span />
      </button>
      <OrganizationSwitcher innerClassName="userOrgSwitcher" />
      <NavLink to={routes.accountInformation} className="innerHeaderUserName">
        <IconProfile size={30} fill="var(--swatch--cta-blue)" />
        <span>{userName}</span>
      </NavLink>
    </header>
  )
}

const mapStateToProps = ({ user, ui }) => ({
  firstName: user.data.firstName,
  lastName: user.data.lastName,
  showMenu: ui.showUserMenu,
})
const mapDispatchToProps = {
  toggleDisplayUserMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
