import { Link } from 'react-router-dom'

import { HISTORY_BACK } from '../../utils/constants'
import { DoubleArrowLeft } from '../SvgIcon'
import './styles.css'

const BackButton = ({ route = HISTORY_BACK, state = {}, className = '', ...props }) => {
  return (
    <Link state={state} to={route} className={`btnBack ${className}`} {...props}>
      <DoubleArrowLeft size={15} />
      Back
    </Link>
  )
}

export default BackButton
