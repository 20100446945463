import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { useMutation } from '@tanstack/react-query'
import TagManager from 'react-gtm-module'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

import {
  INPUT_TYPES,
  REGISTRATION_GTM_EVENTS,
  REGISTRATION_STEPS,
} from '../../../../utils/constants'
import {
  formatDiscountLabel,
  handleCouponApplication,
  generateSubscriptionInfoText,
} from '../../../../utils/functions/registration'
import { updateUserInfo } from '../../../../api/user'
import { setNewDataUser } from '../../../../redux/actions/user'
import { LoadingIcon } from '../../../../components/SvgIcon'
import ControllerField from '../../../../components/HookForm/components/ControllerField'
import FormFooter from './FormFooter'

const PaymentDetailsStep = ({
  control,
  themeId,
  isLoading,
  formState,
  onSuccess,
  currentStep,
  setFormValue,
  isLoadingForm,
  getFormValues,
  getCouponValue,
  setNewDataUser,
  updateCurrentStep,
  updateFormValueState,
  changeLoadingFormState,
}) => {
  const [subscriptionTextInfo, setSubscriptionTextInfo] = useState('')
  const [isPending, setIsPending] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const mutationUpdateUserInfo = useMutation({
    mutationKey: ['updateUserInfo'],
    mutationFn: updateUserInfo,
    onSuccess: ({ data }) => setNewDataUser(data),
  })

  const pushEventSuccessPurchase = () => {
    const { price, themeId } = getFormValues()

    TagManager.dataLayer({
      dataLayer: {
        event: REGISTRATION_GTM_EVENTS.SUCCESSFUL_PURCHASE,
        themeId,
        priceInfo: price,
      },
    })
  }
  const onSuccessSubscription = async () => {
    onSuccess()
    pushEventSuccessPurchase()

    if (
      formState.dirtyFields.firstName ||
      formState.dirtyFields.lastName ||
      formState.dirtyFields.timezone
    ) {
      const { firstName, lastName, timezone } = getFormValues()
      mutationUpdateUserInfo.mutate({
        firstName,
        lastName,
        timezoneName: timezone?.name,
        timezoneOffset: timezone?.timezoneOffset,
      })
    }
  }

  const handlePayment = async (toastId) => {
    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://daydrop.me/login',
        payment_method_data: {
          billing_details: {
            address: {
              country: 'US',
              postal_code: '10001',
            },
          },
        },
      },
    })

    if (result.error) {
      toast.update(toastId, {
        render: result.error.message,
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      })
    } else {
      toast.update(toastId, {
        render: 'You successfully registered',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      })
      await onSuccessSubscription()
    }
  }

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      setIsPending(true)

      const toastId = toast.loading('Loading...')
      if (!stripe || !elements) {
        changeLoadingFormState(false)
        toast.dismiss(toastId)
        return
      }

      await handlePayment(toastId)

      setIsPending(false)
    },
    [formState.dirtyFields.promotionCode, stripe, elements]
  )

  const mutationApplyPromotionCode = useMutation({
    mutationKey: 'applyPromotionCode',
    mutationFn: async (couponValue) => {
      const { expectedTrialPeriod, themeData } = getFormValues()
      return await handleCouponApplication(
        {
          couponValue,
          themeId,
          trialPeriod: expectedTrialPeriod,
          themeName: themeData.themeName,
        },
        setSubscriptionTextInfo
      )
    },
    onSuccess: async (priceInfo, couponValue) => {
      setFormValue('price', priceInfo)
      updateFormValueState('promotionCode', { defaultValue: couponValue })
      if (priceInfo.isForeverFree) {
        await onSuccessSubscription()
      }
    },
  })

  const handleLoaderStart = () => {
    const { price, themeData, expectedTrialPeriod } = getFormValues()
    changeLoadingFormState(true)
    const defaultSubscriptionTextInfo = generateSubscriptionInfoText({
      ...price,
      defaultPrice: price.old,
      discountedPrice: price.new,
      couponDuration: price.duration,
      discountMonthDuration: price.durationInMonths,
      themeName: themeData.themeName,
      trial: expectedTrialPeriod,
    })
    setSubscriptionTextInfo(defaultSubscriptionTextInfo)
  }

  const handleLoaderReady = () => setTimeout(() => changeLoadingFormState(false), 1000)

  // This useEffect is used to add a class to the first element in the line for the subscription text, to track if we need to add a "•" before the text
  useEffect(() => {
    if (subscriptionTextInfo) {
      let lastOffsetTop = -1

      // Select all `span` elements inside `.paymentInfo`
      const elements = document.querySelectorAll('.paymentInfo span')

      elements.forEach((element) => {
        if (element.offsetTop !== lastOffsetTop) {
          element.classList.add('firstInLine')
        } else {
          element.classList.remove('firstInLine')
        }
        lastOffsetTop = element.offsetTop
      })
    }
  }, [subscriptionTextInfo])

  return (
    <form onSubmit={handleSubmit} className="innerStepsRegistration paymentForm">
      <p className="titleForm">{REGISTRATION_STEPS[REGISTRATION_STEPS.length - 1].title}</p>
      <p className="descriptionForm paymentInfo">{subscriptionTextInfo}</p>
      <div className="innerStepsRegistration">
        <div className="innerPaymentForm">
          <PaymentElement
            onLoaderStart={handleLoaderStart}
            onReady={handleLoaderReady}
            options={{
              wallets: { applePay: 'auto', googlePay: 'auto' },
              terms: { card: 'never', googlePay: 'never', applePay: 'never' },
              layout: 'tabs',
              paymentMethodOrder: ['card', 'apple_pay', 'google_pay'],
              fields: { billingDetails: { address: { country: 'never', postalCode: 'never' } } },
            }}
          />
          <div className="innerUserInfo">
            <ControllerField
              name="firstName"
              type={INPUT_TYPES.TEXT}
              defaultValue=""
              rules={{ required: false }}
              control={control}
              label="First name"
            />
            <ControllerField
              name="lastName"
              type={INPUT_TYPES.TEXT}
              defaultValue=""
              rules={{ required: false }}
              control={control}
              label="Last name"
            />
          </div>
          <div className="innerCouponField">
            <div className="position-relative">
              <ControllerField
                name="promotionCode"
                rules={{ required: false }}
                placeholder="Add promotion code"
                innerClassName="innerInputPromotionCode"
                control={control}
              />
              <button
                disabled={
                  !formState.dirtyFields?.promotionCode ||
                  mutationApplyPromotionCode.isPending ||
                  isEmpty(getFormValues('promotionCode'))
                }
                onClick={() => mutationApplyPromotionCode.mutate(getCouponValue())}
                className="applyPromotionCode"
                type="button"
              >
                Apply
              </button>
            </div>
            {getFormValues('price')?.name && (
              <p className="promotionCodeInfo">
                This coupon gives you <b>{formatDiscountLabel(getFormValues('price'))} off!</b>
              </p>
            )}
          </div>
        </div>
        {isLoadingForm && (
          <div className="loadingRegistrationForm">
            <LoadingIcon />
          </div>
        )}
      </div>

      <FormFooter
        isLoading={isLoading || isPending}
        currentStep={currentStep}
        onClickBack={() => updateCurrentStep(currentStep - 1)}
      />
    </form>
  )
}

const mapDispatchToProps = { setNewDataUser }

export default connect(null, mapDispatchToProps)(PaymentDetailsStep)
