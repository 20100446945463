import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import SocketProvider from '../components/SocketProvider'
import Page404 from '../pages/Page404'

import LoginAdmin from '../pages/Admin/Login'
import LoginUser from '../pages/User/Login'

import { ROLES } from '../utils/constants'
import LayoutUser from '../components/LayoutUser'
import LayoutAdmin from '../components/LayoutAdmin'
import UserRegistration from '../pages/Registration/User'
import SwitchOrganization from '../pages/SwitchOrganization'
import OrganizationRegistration from '../pages/Registration/Organization'

import { routes } from './routes'
import { adminPages, userPages } from './pages'
import RenderLayout from './RenderLayout'
import RenderPages from './RenderPages'

const Router = ({ isLoadingApp, userRole }) => {
  if (isLoadingApp) {
    return null
  }

  return (
    <SocketProvider>
      <Routes>
        <Route path={routes.home} element={<LoginUser />} />
        <Route path={routes.userLogin} element={<LoginUser />} />
        <Route path={routes.adminLogin} element={<LoginAdmin />} />

        <Route
          element={
            <RenderLayout Layout={LayoutUser} roles={[ROLES.ROLE_USER, ROLES.ROLE_TESTER]} />
          }
        >
          {userPages.map((item) => (
            <Route
              key={item.name}
              path={item.path}
              element={<RenderPages {...item} Page={item.Component} />}
            />
          ))}
        </Route>

        <Route
          element={
            <RenderLayout Layout={LayoutAdmin} roles={[ROLES.ROLE_ADMIN, ROLES.ROLE_TESTER]} />
          }
        >
          {adminPages.map((item) => (
            <Route
              key={item.name}
              element={<RenderPages {...item} Page={item.Component} />}
              {...item}
            />
          ))}
        </Route>

        <Route
          element={
            <RenderLayout
              Layout={userRole === ROLES.ROLE_ADMIN ? LayoutAdmin : LayoutUser}
              roles={[ROLES.ROLE_ADMIN, ROLES.ROLE_USER]}
            />
          }
        >
          <Route
            path={
              userRole === ROLES.ROLE_ADMIN ? routes.adminSwitchAccount : routes.userSwitchAccount
            }
            element={<RenderPages Page={SwitchOrganization} />}
          />
        </Route>

        <Route path={routes.registration} element={<UserRegistration />} />
        <Route path={routes.orgRegistration} element={<OrganizationRegistration />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </SocketProvider>
  )
}

const mapStateToProps = ({ user }) => ({
  userRole: user.role,
})

export default connect(mapStateToProps)(Router)
