import './styles.css'

const PrimaryButton = ({ text = '', customClass = '', Icon = null, iconProps, ...rest }) => {
  return (
    <button className={`primaryButton ${customClass}`} {...rest}>
      {Icon && <Icon size={20} style={{ fill: '#FFFFFF' }} {...iconProps} />}
      {text}
    </button>
  )
}

export default PrimaryButton
