import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import { OTP_FIELD } from '../../utils/constantsForms'
import { confirmChangePhoneNumber } from '../../api/user'
import { setNewPhoneNumber } from '../../redux/actions/user'
import { Cross, IconPhone } from '../SvgIcon'
import HookForm from '../HookForm'
import PrimaryButton from '../PrimaryButton'

const ModalOtpVerification = ({ show, setNewPhoneNumber, onHide, onSuccess }) => {
  const formRef = useRef(null)
  const [searchParams] = useSearchParams()
  const mutationConfirmUpdatePhone = useMutation({
    mutationKey: ['confirmUpdatePhone'],
    mutationFn: confirmChangePhoneNumber,
    onSuccess: () => {
      setNewPhoneNumber(searchParams.get('phone'))
      onHide()
      onSuccess()
    },
  })

  return (
    <Modal size="md" centered show={show} onHide={onHide}>
      <div className="d-flex justify-content-between align-items-center gap-2 w-100 border-bottom pb-3">
        <div className="d-flex align-items-center gap-2">
          <IconPhone className="iconBin" size={32} />
          <h2 className="title mw-100">Verify Your Code</h2>
        </div>
        <Cross size={23} onClick={onHide} className="cursorPointer" />
      </div>
      <div className="d-flex flex-column align-items-center w-100 mt-3">
        <h3 className="text-center mb-1">Enter the Verification Code</h3>
        <p className="text-center mb-2">
          We’ve sent a verification code to your phone via text message. Please enter it below to
          confirm your phone number and complete the update.
        </p>
        <HookForm
          ref={formRef}
          className="w-100"
          onSubmit={mutationConfirmUpdatePhone.mutate}
          fields={[
            {
              ...OTP_FIELD,
              onSuccess: () => formRef.current?.handleSubmit(mutationConfirmUpdatePhone.mutate)(),
            },
          ]}
          Footer={() => (
            <PrimaryButton
              disabled={mutationConfirmUpdatePhone.isPending}
              customClass="mt-4 mw-100 w-100 big"
              text="Verify"
            />
          )}
        />
      </div>
    </Modal>
  )
}

const mapDispatchToProps = { setNewPhoneNumber }

export default connect(null, mapDispatchToProps)(ModalOtpVerification)
