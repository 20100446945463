import r from '../utils/request'

export const updateUserInfo = (params) => {
  return r.put('/users', params)
}

export const updateUserInfoWithToast = (params, toastMessages = {}) => {
  return r.putWithToast('/users', params, toastMessages)
}

export const manageThemeSubscription = (params, toastMessages) => {
  return r.putWithToast(`/users/subscriptions/${params.themeId}/manage`, params, toastMessages)
}

export const getUserCalendarPlan = (params) => {
  return r.get(`/users/calendar`, params)
}

export const changePhoneNumber = (params) => {
  return r.put(`/users/phone`, params)
}

export const confirmChangePhoneNumber = (params) => {
  return r.putWithToast(`/users/confirm-phone`, params, {
    pending: 'Verification...',
    success: 'Phone Updated',
    error: 'Invalid code',
  })
}

export const getUserContents = (params) => {
  return r.get(`/themes/content-links/`, params)
}

export const getThemeDetails = (themId) => {
  return r.get(`/themes/${themId}`)
}

export const getUserAchievements = (params) => {
  return r.get('/users/achievements', params)
}

export const toggleFavoriteContent = (contentId) => {
  return r.put(`/content/${contentId}/toggle-favorite`)
}

export const getUserTags = () => {
  return r.get('/users/tags')
}

export const getUserSubscription = (params) => {
  return r.get('/users/subscriptions', params)
}
export const getSubscriptionStatusesCount = (params) => {
  return r.get('/users/subscriptions/statuses', params)
}

export const getAchievementsCounter = (themeId) => {
  return r.get(`/users/themes/${themeId}/achievement/total`)
}

export const getUserThemeProgress = (themeID) => {
  return r.get(`/themes/${themeID}/progress`)
}
