import UserHome from '../pages/User/Home'
import AccountInformation from '../pages/User/AccountInformation'
import Achievements from '../pages/User/Achievements'
import ThemeDetails from '../pages/User/ThemeDetails'
import UserChats from '../pages/User/Chats'
import Themes from '../pages/User/Themes'
import Gifts from '../pages/User/Gifts'
import Shop from '../pages/User/Shop'

import OrganizationSettings from '../pages/Admin/OrganizationSettings'
import GiftPricingAnalytics from '../pages/Admin/GiftPricingAnalytics'
import UserThemeAnalytic from '../pages/Admin/UserThemeAnalytic'
import ContentStatistics from '../pages/Admin/ContentStatistics'
import SurveyAnalytics from '../pages/Admin/SurveyAnalytics'
import ProgramThemes from '../pages/Admin/ProgramThemes'
import ThemeSettings from '../pages/Admin/ThemeSettings'
import ThemeContent from '../pages/Admin/ThemeContent'
import GiftPricing from '../pages/Admin/GiftPricing'
import Dashboard from '../pages/Admin/Dashboard'
import UsersChat from '../pages/Admin/UsersChat'
import Feedback from '../pages/Admin/Feedback'
import Programs from '../pages/Admin/Programs'
import Message from '../pages/Admin/Message'
import Theme from '../pages/Admin/Theme'
import Users from '../pages/Admin/Users'
import Tags from '../pages/Admin/Tags'
import User from '../pages/Admin/User'

import { routes } from './routes'

export const userPages = [
  {
    path: routes.userHome,
    name: 'UserHome',
    Component: UserHome,
  },
  {
    path: routes.accountInformation,
    name: 'AccountInformation',
    Component: AccountInformation,
  },
  {
    path: routes.userThemeDetails,
    name: 'ThemeDetails',
    Component: ThemeDetails,
  },
  {
    path: routes.userAchievements,
    name: 'Achievements',
    Component: Achievements,
  },
  {
    path: routes.shop,
    name: 'Shop',
    Component: Shop,
  },
  {
    path: routes.userChat,
    name: 'UserChat',
    Component: UserChats,
  },
  {
    path: routes.userGifts,
    name: 'UserGifts',
    Component: Gifts,
  },
  {
    path: routes.userThemes,
    name: 'UserThemes',
    Component: Themes,
  },
]

export const adminPages = [
  {
    path: routes.accountSettings,
    name: 'AccountSettings',
    Component: OrganizationSettings,
  },
  {
    path: routes.dashboard,
    name: 'Dashboard',
    Component: Dashboard,
  },
  {
    path: routes.adminGiftCoupons,
    name: 'GiftPricing',
    Component: GiftPricing,
    hasAccess: ({ currentCompany }) => currentCompany?.permissions?.useGiftCoupons,
  },
  {
    path: routes.adminGiftCouponsAnalytics,
    name: 'GiftPricingAnalytics',
    Component: GiftPricingAnalytics,
    hasAccess: ({ currentCompany }) => currentCompany?.permissions?.useGiftCoupons,
  },
  {
    path: routes.users,
    name: 'Users',
    Component: Users,
  },
  {
    path: routes.user,
    name: 'User',
    Component: User,
  },
  {
    path: routes.userThemeAnalytic,
    name: 'userThemeAnalytic',
    Component: UserThemeAnalytic,
  },
  {
    path: routes.themeSettings,
    name: 'ThemeSettings',
    Component: ThemeSettings,
    hasAccess: ({ currentCompany }) => currentCompany.profileProgress >= 100,
  },
  {
    path: routes.message,
    name: 'Message',
    Component: Message,
  },
  { path: routes.tags, name: 'Tags', Component: Tags },
  {
    path: routes.feedback,
    name: 'Feedback',
    Component: Feedback,
    hasAccess: ({ currentCompany }) => currentCompany.permissions?.useContent,
  },
  {
    path: routes.messaging,
    name: 'UsersChat',
    Component: UsersChat,
  },
  {
    path: routes.surveyAnalytics,
    name: 'SurveyAnalytics',
    Component: SurveyAnalytics,
  },
  {
    path: routes.programs,
    name: 'Programs',
    Component: Programs,
    hasAccess: ({ currentCompany }) => currentCompany.profileProgress >= 100,
  },
  {
    path: routes.programThemes,
    name: 'ProgramThemes',
    Component: ProgramThemes,
    hasAccess: ({ currentCompany }) => currentCompany.profileProgress >= 100,
  },
  {
    path: routes.theme,
    name: 'Theme',
    Component: Theme,
    hasAccess: ({ currentCompany }) => currentCompany.profileProgress >= 100,
  },
  {
    path: routes.adminContentStatistics,
    name: 'ContentStatistics',
    Component: ContentStatistics,
  },
  {
    path: routes.themeContent,
    name: 'ThemeContent',
    Component: ThemeContent,
  },
]
