import { useCallback } from 'react'
import { isEmpty, isNil } from 'lodash'
import { Tooltip, Whisper } from 'rsuite'

import { CloudUpload, Info } from '../SvgIcon'

import './styles.css'

const InputUploadAudio = ({
  id = '',
  value,
  onChange,
  label,
  error,
  showIconInfo,
  innerClassName,
  infoText,
}) => {
  const updateFile = useCallback((e) => {
    const audio = e.target?.files?.[0]
    if (!isNil(audio)) {
      console.log(audio)
      const audioUrl = URL.createObjectURL(audio)
      onChange({ file: audio, audioUrl })
    }
  }, [])

  return (
    <div className={`w-100 ${innerClassName}`}>
      <div className="d-flex align-items-center gap-1">
        {!isEmpty(label) && (
          <div className="d-flex align-items-center gap-1 inputLabel">
            <label>{label}</label>
            {showIconInfo && (
              <Whisper
                placement="top"
                trigger="hover"
                speaker={
                  <Tooltip>
                    <p>{infoText}</p>
                  </Tooltip>
                }
              >
                <span className="d-flex">
                  <Info fill="var(--darkest-gray)" size={15} />
                </span>
              </Whisper>
            )}
          </div>
        )}
        {!isNil(value?.audioUrl) && (
          <label
            htmlFor={id}
            style={error ? { color: 'red' } : {}}
            className="ms-auto d-flex align-items-center gap-2 cursorPointer"
          >
            <CloudUpload size={22} fill="var(--catalina-blue)" />{' '}
            <span className="uploadAudioLabel">Upload new file</span>
          </label>
          //     <label
          //     htmlFor={id}
          //   onClick={updateFile}
          //   className="ms-auto d-flex align-items-center gap-2"
          // >
          //   <CloudUpload size={40} fill="var(--catalina-blue)" /> Upload audio
          //     </label>
        )}
      </div>
      {!isNil(value?.audioUrl) ? (
        <audio controls className="w-100 mt-1" src={value?.audioUrl}></audio>
      ) : (
        <label htmlFor={id} style={error ? { borderColor: 'red' } : {}} className="audioDropzone">
          <CloudUpload size={22} fill="var(--catalina-blue)" /> <span>Upload audio file</span>
        </label>
      )}
      <input id={id} onChange={updateFile} className="d-none" type="file" accept=".mp3" />
    </div>
  )
}

export default InputUploadAudio
