import { Modal } from 'react-bootstrap'
import { Uploader } from 'rsuite'

import { CloudUpload } from '../SvgIcon'

const ModalUpload = ({ show, title, onHide, onUpload }) => {
  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <Modal.Header style={{ borderBottom: 'none' }} closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Uploader
          onChange={(data) => onUpload(data, onHide)}
          draggable
          action=""
          accept=".xlsx, .xls"
          autoUpload={false}
          className="position-relative cursorPointer"
          multiple={false}
          disabledFileItem
          disableMultipart
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 200,
            }}
          >
            <CloudUpload size={40} fill="var(--catalina-blue)" />
            <span className="blockTitle sm mt-3 mb-2">
              Drag & Drop or <span className="highLightLink">Choose file</span> to upload
            </span>
            <span className="blockDescription sm">Supported format: XLS, XLSX</span>
          </div>
        </Uploader>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpload
