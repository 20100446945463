import axios from 'axios'
import { isArray, snakeCase } from 'lodash'
import { toast } from 'react-toastify'

import { API_ROOT, DEFAULT_TOAST_MESSAGES } from './constants'
import { camelize, snakeize } from './helpers'

const rawRequest = async (url, method = 'get', options = {}, apiRoot, config, headers = {}) => {
  const referer = global.window?.location?.href || '/'

  let params = {
    ...options,
    url,
    method,
    headers: {
      ['X-Referer']: referer,
      ...headers,
    },
  }
  const pickedRoot = apiRoot ?? API_ROOT
  params.url = pickedRoot + url

  return axios(params, config).then(({ data }) => camelize(data))
}

const requestExternalApi = async (url, method = 'get', options = {}) => {
  let params = { ...options, url, method }
  return axios(params)
}

const getRequest = async (url, data = {}, apiRoot) => {
  const formattedData = snakeize(data)
  return rawRequest(url, 'get', { params: formattedData }, apiRoot)
}

const getRequestWithToast = async (url, data = {}, apiRoot, toastPromiseMessages = {}) => {
  const formattedData = snakeize(data)
  return toast.promise(rawRequest(url, 'get', { params: formattedData }, apiRoot), {
    ...DEFAULT_TOAST_MESSAGES,
    ...toastPromiseMessages,
  })
}

const postRequest = async (url, data = {}) => {
  const formattedData = snakeize(data)
  return rawRequest(url, 'post', { data: formattedData })
}
const postRequestWithToast = async (url, data = {}, toastPromiseMessages = {}) => {
  const formattedData = snakeize(data)
  return toast.promise(rawRequest(url, 'post', { data: formattedData }), {
    ...DEFAULT_TOAST_MESSAGES,
    ...toastPromiseMessages,
  })
}

const putRequest = async (url, data = {}) => {
  const formattedData = snakeize(data)
  return rawRequest(url, 'put', { data: formattedData })
}
const putRequestWithToast = async (url, data = {}, toastPromiseMessages = {}) => {
  const formattedData = snakeize(data)
  return toast.promise(rawRequest(url, 'put', { data: formattedData }), {
    ...DEFAULT_TOAST_MESSAGES,
    ...toastPromiseMessages,
  })
}

const deleteRequest = async (url, data = {}) => {
  const formattedData = snakeize(data)
  return rawRequest(url, 'delete', { data: formattedData })
}
const deleteRequestWithToast = async (url, data = {}, toastPromiseMessages = {}) => {
  const formattedData = snakeize(data)
  return toast.promise(rawRequest(url, 'delete', { data: formattedData }), {
    ...DEFAULT_TOAST_MESSAGES,
    ...toastPromiseMessages,
  })
}

const uploadRequest = async (url, data = {}, method = 'put') => {
  const formData = new FormData()
  for (const field of Object.entries(data)) {
    const [key, value] = field
    const modifyValue = !isArray(value) ? value : JSON.stringify(value)

    formData.append(snakeCase(key), modifyValue)
  }

  return rawRequest(url, method, { data: formData })
}
const uploadRequestWithToast = async (
  url,
  data = {},
  method = 'put',
  toastPromiseMessages = {}
) => {
  const formData = new FormData()
  for (const field of Object.entries(data)) {
    const [key, value] = field
    const modifyValue = !isArray(value) ? value : JSON.stringify(value)

    formData.append(snakeCase(key), modifyValue)
  }

  return toast.promise(rawRequest(url, method, { data: formData }), {
    ...DEFAULT_TOAST_MESSAGES,
    ...toastPromiseMessages,
  })
}

const setHandlers = (onSuccess, onError) => {
  const defaultSuccessHandler = (response) => {
    return response
  }
  const defaultErrorHandler = (e) => {
    console.log(`\n\nERROR\n\n${e}`)
    throw e
  }

  axios.interceptors.response.use(
    onSuccess ? onSuccess : defaultSuccessHandler,
    onError ? onError : defaultErrorHandler
  )
}

const downloadFile = async (url, method = 'get', filename, data, apiRoot) => {
  const referer = global.window?.location?.href || '/'
  const pickedRoot = apiRoot ?? API_ROOT
  const formattedData = snakeize(data)

  const blobData = await axios({
    url: pickedRoot + url,
    params: formattedData,
    method,
    responseType: 'arraybuffer',
    headers: {
      ['X-Referer']: referer,
    },
  })

  const blob = new Blob([blobData.data], { type: 'application/octet-stream' })

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
}

const getToken = () => axios.defaults.headers.common['Authorization']

export default {
  get: getRequest,
  getWithToast: getRequestWithToast,
  post: postRequest,
  postWithToast: postRequestWithToast,
  delete: deleteRequest,
  deleteWithToast: deleteRequestWithToast,
  put: putRequest,
  putWithToast: putRequestWithToast,
  upload: uploadRequest,
  uploadWithToast: uploadRequestWithToast,
  requestExternalApi,
  rawRequest,
  setHandlers,
  getToken,
  downloadFile,
}
