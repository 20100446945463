import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { Dropdown } from 'rsuite'

import { ThreeDots } from '../../../../components/SvgIcon'
import { manageThemeSubscription } from '../../../../api/admin/user'
import {
  ALL_NON_RENEW_SUBSCRIPTION_STATUSES,
  ALL_SUBSCRIPTABLE_TYPES_CAN_CANCELLED,
  ALL_SUBSCRIPTION_STATUSES_CAN_CANCELLED,
  CANCEL_DESCRIPTION_ADMIN_TEXT,
  SUBSCRIPTABLE_TYPES,
  SUBSCRIPTION_API_ACTIONS,
  SUBSCRIPTION_API_ACTIONS_INFO,
  SUBSCRIPTION_STATUSES,
} from '../../../../utils/constants'
import ModalCancelSubscription from '../../../../components/Modals/ModalCancelSubscription'

const DropdownAction = ({ status, subscriptableType, endSubscription, themeName }) => {
  const [showModalConfirmCancellation, setShowModalConfirmCancellation] = useState(false)
  const { themeId, userId } = useParams()
  const [, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const isPaidContentActive = useMemo(
    () =>
      subscriptableType === SUBSCRIPTABLE_TYPES.PAID_CONTENTS_SUBSCRIPTION &&
      status === SUBSCRIPTION_STATUSES.ACTIVE,
    [subscriptableType, status]
  )

  const canCancel = useMemo(
    () =>
      ALL_SUBSCRIPTION_STATUSES_CAN_CANCELLED.includes(status) &&
      ALL_SUBSCRIPTABLE_TYPES_CAN_CANCELLED.includes(subscriptableType),
    [status, subscriptableType]
  )

  const canCancelImmediately = useMemo(
    () => canCancel || ALL_NON_RENEW_SUBSCRIPTION_STATUSES.includes(status),
    [canCancel, status]
  )

  const mutationManageSubscription = useMutation({
    mutationKey: ['manageSubscription', themeId, userId],
    mutationFn: (params) =>
      manageThemeSubscription(
        { ...params, themeId, userId },
        {
          success: SUBSCRIPTION_API_ACTIONS_INFO[params.action].successMessages(themeName),
        }
      ),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['themeDetails', themeId, userId] }),
  })

  const displayModalConfirmCancellation = useCallback(
    (additionalParams) => {
      setSearchParams({
        userId,
        status,
        themeId,
        themeName,
        cancelAt: endSubscription,
        ...additionalParams,
      })
      setShowModalConfirmCancellation(true)
    },
    [status, themeId, themeName, endSubscription]
  )

  if (!isPaidContentActive && !canCancel && !canCancelImmediately) {
    return null
  }

  return (
    <div>
      <Dropdown
        renderToggle={(props, ref) => <ThreeDots size={25} {...props} ref={ref} />}
        placement="bottomEnd"
      >
        {isPaidContentActive && (
          <Dropdown.Item
            onClick={() =>
              mutationManageSubscription.mutate({ action: SUBSCRIPTION_API_ACTIONS.PAUSE })
            }
          >
            Pause
          </Dropdown.Item>
        )}
        {canCancel && (
          <Dropdown.Item
            onClick={() =>
              displayModalConfirmCancellation({
                cancelAt: '01.01.2025',
                action: SUBSCRIPTION_API_ACTIONS.CANCEL,
              })
            }
          >
            Cancel
          </Dropdown.Item>
        )}
        {canCancelImmediately && (
          <Dropdown.Item
            onClick={() =>
              displayModalConfirmCancellation({
                action: SUBSCRIPTION_API_ACTIONS.CANCEL_IMMEDIATELY,
              })
            }
          >
            Cancel immediately
          </Dropdown.Item>
        )}
      </Dropdown>
      <ModalCancelSubscription
        show={showModalConfirmCancellation}
        descriptionTexts={CANCEL_DESCRIPTION_ADMIN_TEXT}
        onHide={() => setShowModalConfirmCancellation(false)}
      />
    </div>
  )
}

export default DropdownAction
