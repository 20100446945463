import './style.css'

const Page404 = () => {
  return (
    <div className="inner404">
      <p className="title404">404</p>
      <p className="supTitleNotFound">Ooops!...Page not found.</p>
    </div>
  )
}

export default Page404
