import { Form } from 'react-bootstrap'
import { isEmpty } from 'lodash'
import { Tooltip, Whisper } from 'rsuite'

import './styles.css'
import { Info } from '../SvgIcon'
import PasswordStrengthMeter from '../PasswordStrengthMeter'

const CustomInput = ({
  error,
  innerClassName = '',
  label = '',
  as = 'input',
  inputRef = null,
  inputType = 'text',
  showIconInfo = false,
  infoText = '',
  showBottomDescription = false,
  bottomDescription = '',
  showPasswordStrength = false,
  ...rest
}) => {
  return (
    <div className={innerClassName}>
      {!isEmpty(label) && (
        <div className="d-flex align-items-center gap-1 inputLabel">
          <label>{label}</label>
          {showIconInfo && (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>
                  <p>{infoText}</p>
                </Tooltip>
              }
            >
              <span className="d-flex">
                <Info fill="var(--darkest-gray)" size={15} />
              </span>
            </Whisper>
          )}
        </div>
      )}
      <Form.Control
        {...rest}
        as={as}
        type={inputType}
        ref={inputRef}
        className={`inputStyle ${error ? 'errorInputText' : ''}`}
      />
      {showBottomDescription && <p className="inputBottomDescription">{bottomDescription}</p>}
      {showPasswordStrength && <PasswordStrengthMeter password={rest.value} />}
    </div>
  )
}

export default CustomInput
