import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { PURCHASE_GIFT_COUPON_FIELDS } from '../../../utils/constantsForms'
import { getAllAvailableGiftCoupons } from '../../../api/giftCoupons'
import { setLoadingUserContent } from '../../../redux/actions/ui'
import GiftImage from '../../../components/GiftImage'
import PrimaryButton from '../../../components/PrimaryButton'
import GiftCardPreview from '../../../components/GiftCardPreview'
import ControllerField from '../../../components/HookForm/components/ControllerField'
import ModalConfirmPurchaseGiftCoupon from '../../../components/Modals/ModalConfirmPurchaseGiftCoupon'

import './styles.css'

const Gifts = ({ userName }) => {
  const [showModalConfirmPurchase, setShowModalConfirmPurchase] = useState(false)
  const dispatch = useDispatch()
  const { control, watch, reset, handleSubmit } = useForm()
  const giftCardInfo = {
    from: watch('senderName'),
    message: watch('message'),
    to: watch('name'),
    deliverAt: watch('deliverAt'),
    giftId: watch('giftInfo')?.id,
    email: watch('email'),
    giftName: watch('giftInfo')?.name,
    giftImg: watch('giftInfo')?.photoUrl,
    price: { old: watch('giftInfo')?.price, new: watch('giftInfo')?.price },
  }

  const { data: availableGifts, isLoading: isLoadingGifts } = useQuery({
    queryKey: ['allAvailableGiftCoupons'],
    queryFn: getAllAvailableGiftCoupons,
  })

  useEffect(() => {
    dispatch(setLoadingUserContent(isLoadingGifts))
    if (!isLoadingGifts) {
      reset({ senderName: userName ?? 'Friend', giftInfo: availableGifts?.data[0] })
    }
  }, [isLoadingGifts])

  if (isEmpty(availableGifts?.data) && !isLoadingGifts) {
    return (
      <div className="innerGift flex-grow-1 d-flex flex-column align-items-center justify-content-center">
        <img
          className="emptyGiftListImg"
          src="/images/emptyGiftCoupons.png"
          alt="empty-gift-list"
        />
        <p className="emptyGiftText">Currently, there are no available gift coupons.</p>
      </div>
    )
  }

  return (
    <section className="formGiftCouponPurchase">
      <div className="landingContainer innerGiftCard">
        <div className="giftCardPreview">
          <GiftCardPreview
            to={watch('name')}
            message={watch('message')}
            from={watch('senderName')}
            giftName={watch('giftInfo')?.name}
            priceInfo={{ old: watch('giftInfo')?.price, new: watch('giftInfo')?.price }}
            giftImg={watch('giftInfo')?.photoUrl}
          />
        </div>
        <div className="innerFormPurchaseGift">
          <Controller
            name="giftInfo"
            control={control}
            defaultValue={availableGifts?.data[0]}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <div className="listGiftCoupons">
                {availableGifts?.data.map((giftInfo) => (
                  <GiftImage
                    key={giftInfo?.id}
                    alt={giftInfo?.name}
                    imgSrc={giftInfo?.photoUrl}
                    onClick={() => onChange(giftInfo)}
                    className={`giftImg ${value?.id === giftInfo?.id ? 'selectedGift' : ''}`}
                  />
                ))}
              </div>
            )}
          />
          <h3 className="titleFormPurchaseGift">Delivery info</h3>
          <form
            className="formPurchaseGiftCoupon"
            onSubmit={handleSubmit(() => setShowModalConfirmPurchase(true))}
          >
            {PURCHASE_GIFT_COUPON_FIELDS.map(({ name, rules, ...rest }) => (
              <ControllerField key={name} name={name} control={control} rules={rules} {...rest} />
            ))}
            <div className="flex-grow-1">
              <PrimaryButton customClass="w-100 mw-100" text="Order now" />
            </div>
          </form>
        </div>
      </div>
      <ModalConfirmPurchaseGiftCoupon
        giftCardInfo={giftCardInfo}
        show={showModalConfirmPurchase}
        onHide={() => setShowModalConfirmPurchase(false)}
        priceInfo={giftCardInfo?.price}
      />
    </section>
  )
}

export default connect(({ user }) => ({
  userName: user.data.firstName,
}))(Gifts)
