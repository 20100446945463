import { useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'

import { IconPause, IconPlay } from '../SvgIcon'
import ImageLoader from '../ImageLoader'

import './styles.css'

const PreviewContent = ({ title, body, bgImageUrl, audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)

  const IconControl = useMemo(() => (isPlaying ? IconPause : IconPlay), [isPlaying])

  return (
    <div className="previewContent">
      <ImageLoader src={bgImageUrl} innerClassName="innerContentImg" />
      <div className="block">
        <p className="title">{title}</p>
        <p className="body">{body}</p>
        <button
          disabled={isEmpty(audioUrl)}
          onClick={() => {
            isPlaying ? audioRef.current.pause() : audioRef.current.play()
          }}
          className="bg-transparent mt-2"
        >
          <IconControl size={20} />
        </button>
      </div>
      <audio
        ref={audioRef}
        src={audioUrl}
        className="d-none"
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)}
      />
    </div>
  )
}

export default PreviewContent
