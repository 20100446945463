import r from '../../utils/request'

export const getProgramThemes = (params) => {
  return r.get(`/admin/programs/${params.id}/themes`, params)
}

export const getAllThemes = (params) => {
  return r.get('/admin/themes', params)
}

export const getThemeDetails = (themeId) => {
  return r.get(`/admin/themes/${themeId}`)
}

export const createTheme = (params) => {
  return r.postWithToast('/admin/themes', params, { success: `Theme ${params.name} created` })
}

export const publishTheme = (themeId, toastMessages) => {
  return r.postWithToast(`/admin/themes/${themeId}/publish`, {}, toastMessages)
}

export const unpublishTheme = (themeId) => {
  return r.postWithToast(
    `/admin/themes/${themeId}/unpublish`,
    {},
    {
      success: 'Theme deactivated',
    }
  )
}

export const duplicateTheme = ({ themeId, ...restParams }) => {
  return r.postWithToast(`/admin/themes/${themeId}/duplicate`, restParams, {
    success: 'Duplicate theme created',
  })
}

export const getVcfContact = (id) => {
  return r.get(`/admin/themes/${id}/contact`)
}
export const createVcfContact = (id, params) => {
  return r.uploadWithToast(`/admin/themes/${id}/contact`, params, 'post', {
    success: 'VCF contact updated successfully',
  })
}
