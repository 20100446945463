import { createAction } from 'redux-actions'

const SET_LOADING_APP = 'SET_LOADING_APP'
const SET_LOADING_USER_CONTENT = 'SET_LOADING_USER_CONTENT'
const LOADING_ADMIN_PROJECT_DATA = 'LOADING_APP'
const SET_MODAL_ERROR_DATA = 'SET_MODAL_ERROR_DATA'
const SET_MODAL_SHARE_CONFIG = 'SET_MODAL_SHARE_CONFIG'
const SET_SHOW_MODAL_SUPPORT = 'SET_SHOW_MODAL_SUPPORT'
const DISPLAY_ADMIN_MENU = 'DISPLAY_ADMIN_MENU'
const TOGGLE_DISPLAY_ADMIN_MENU = 'TOGGLE_DISPLAY_ADMIN_MENU'
const TOGGLE_DISPLAY_USER_MENU = 'TOGGLE_DISPLAY_USER_MENU'
const SET_SHOW_MODAL_CANCEL_SUBSCRIPTION = 'SET_SHOW_MODAL_CANCEL_SUBSCRIPTION'
const SET_SHOW_EDITOR_GLOBAL_ONBOARDING_SMS = 'SET_SHOW_EDITOR_GLOBAL_ONBOARDING_SMS'

const setLoadingApp = createAction(SET_LOADING_APP)
const setLoadingUserContent = createAction(SET_LOADING_USER_CONTENT)
const setModalErrorData = createAction(SET_MODAL_ERROR_DATA)
const setModalShareConfig = createAction(SET_MODAL_SHARE_CONFIG)
const setShowModalSupport = createAction(SET_SHOW_MODAL_SUPPORT)
const displayAdminMenu = createAction(DISPLAY_ADMIN_MENU)
const toggleDisplayAdminMenu = createAction(TOGGLE_DISPLAY_ADMIN_MENU)
const toggleDisplayUserMenu = createAction(TOGGLE_DISPLAY_USER_MENU)
const setLoadingAdminProjectData = createAction(LOADING_ADMIN_PROJECT_DATA)
const setShowModalCancelSubscription = createAction(SET_SHOW_MODAL_CANCEL_SUBSCRIPTION)
const setShowEditorGlobalOnboardingSMS = createAction(SET_SHOW_EDITOR_GLOBAL_ONBOARDING_SMS)

export {
  setLoadingApp,
  setLoadingUserContent,
  setModalErrorData,
  setModalShareConfig,
  setShowModalSupport,
  displayAdminMenu,
  toggleDisplayUserMenu,
  toggleDisplayAdminMenu,
  setLoadingAdminProjectData,
  setShowModalCancelSubscription,
  setShowEditorGlobalOnboardingSMS,
}
