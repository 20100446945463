import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { Drawer } from 'rsuite'

import {
  INPUT_TYPES,
  PRICE_TYPES,
  PRICE_TYPES_SETTINGS,
  THEME_SETTINGS_PRICE_TYPES,
} from '../../../utils/constants'
import { createTheme } from '../../../api/admin/themes'
import { updateThemeInfo } from '../../../api/admin/themeSettings'
import ControllerField from '../../HookForm/components/ControllerField'
import PrimaryButton from '../../PrimaryButton'
import Button from '../../Button'

import './styles.css'

const ModalTheme = ({ show, themeId, defaultValues, onSuccessUpdate, onHide, title }) => {
  const { programId } = useParams()
  const navigation = useNavigate()
  const { control, handleSubmit, watch, reset, formState } = useForm({
    defaultValues: { priceType: PRICE_TYPES.RECURRING },
  })

  const mutationCreateTheme = useMutation({
    mutationKey: ['createTheme', programId],
    mutationFn: (params) => createTheme({ ...params, programId }),
    onSuccess: ({ data }) => navigation(`/admin/programs/${programId}/${data?.id}`),
  })

  const mutationUpdateTheme = useMutation({
    mutationKey: ['updateTheme', programId],
    mutationFn: (params) => updateThemeInfo({ ...params, themeId }),
    onSuccess: onSuccessUpdate,
  })

  const onSubmit = useMemo(() => (themeId ? mutationUpdateTheme : mutationCreateTheme), [themeId])

  useEffect(() => {
    if (!isEmpty(defaultValues)) {
      reset(defaultValues, { keepDirty: false })
    }
  }, [defaultValues])

  return (
    <Drawer placement="right" open={show} className="modalCreateTheme" onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title className="blockTitle mb-0 mt-1">{title}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="p-3 createThemeBody">
        <form onSubmit={handleSubmit(onSubmit.mutate)}>
          <ControllerField
            control={control}
            name="name"
            label="Name (required)"
            innerClassName="mb-3 mt-3"
            defaultValue=""
            rules={{
              required: true,
            }}
          />
          <ControllerField
            control={control}
            name="contentTypes"
            label="Content types"
            innerClassName="underline mb-3 pb-4 mt-3"
            defaultValue={['text']}
            rules={{
              required: true,
            }}
            checkboxList={[
              { label: 'Text', value: 'text' },
              { label: 'Audio', value: 'audio' },
              { label: 'Video', value: 'video' },
            ]}
            checkboxListClassName="d-flex gap-2 align-items-center"
            checkboxClassName="checkboxThemeContentType"
            type={INPUT_TYPES.CHECKBOX_LIST}
          />
          <ControllerField
            control={control}
            name="priceType"
            innerClassName="innerPriceTypeRadioButtons mb-3"
            type={INPUT_TYPES.RADIO_BUTTON}
            defaultValue={PRICE_TYPES.RECURRING}
            buttons={THEME_SETTINGS_PRICE_TYPES}
          />
          <ControllerField
            name="priceAmount"
            control={control}
            rules={{ required: true, validate: (value) => value > 0 }}
            defaultValue=""
            type={INPUT_TYPES.INPUT_MASK}
            isNumberMask
            placeholder="0.00"
            innerClassName="innerPriceInput mb-3"
            label="Total price"
            showIconInfo
            infoText='The subscription price is entered in this field. If you choose "One-Time Payment", the specified amount will be charged only once after successful registration. If you choose "Recurring Payment", the specified amount will be automatically charged monthly or annually, depending on the plan you choose.'
            defaultMaskOptions={{
              prefix: '',
              suffix: '',
              includeThousandsSeparator: true,
              thousandsSeparatorSymbol: ' ',
              allowDecimal: true,
              decimalSymbol: '.',
              decimalLimit: 2,
              integerLimit: 7,
              allowNegative: false,
              allowLeadingZeroes: false,
            }}
          />
          <ControllerField
            name="priceExtensionDays"
            control={control}
            rules={{ required: true, validate: (value) => value > 0 }}
            defaultValue=""
            placeholder=""
            label="Message count"
            innerClassName="mb-3"
            inputType="number"
            showIconInfo
            hideControllerField={watch('priceType') === PRICE_TYPES.RECURRING}
            infoText="In this field, you can enter the predicted number of messages that will be sent in the program. This number determines the number of days during which the user will be able to use the program. Enter the expected number of messages to determine the duration of access."
          />
          <p className="blockDescription sm">
            {PRICE_TYPES_SETTINGS[watch('priceType')]?.description}
          </p>
        </form>
      </Drawer.Body>
      <Drawer.Actions className="drawerActionModalFooter">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton
          disabled={onSubmit.isPending || !formState.isValid || !formState.isDirty}
          onClick={handleSubmit(onSubmit.mutate)}
          text={themeId ? 'Update theme' : 'Create theme'}
        />
      </Drawer.Actions>
    </Drawer>
  )
}

export default ModalTheme
