import { useState } from 'react'
import { Table } from 'rsuite'

import { IconImport, Plus } from '../../../components/SvgIcon'
import PrimaryButton from '../../../components/PrimaryButton'
import { ImageCell } from '../../../components/Table'
import Button from '../../../components/Button'
import Pagination from '../../../components/Pagination'
import ModalUploadThemeContent from '../../../components/Modals/ModalUpload'
import ModalThemeContent from '../../../components/Modals/ModalThemeContent'

import './styles.css'

const { Column, HeaderCell, Cell } = Table

const ThemeContent = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [showModalContent, setShowModalContent] = useState(false)
  const [showModalUploadContent, setShowModalUploadContent] = useState(false)
  const [selectedFeedPage, setSelectedFeedPage] = useState(1)
  const [previewMode, setPreviewMode] = useState(false)

  return (
    <div className="containerAdminMainInfo themeContent">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1 className="titleAdminPage mb-0 fw-bold">Meditation content</h1>
        <PrimaryButton
          onClick={() => setShowModalContent(true)}
          Icon={Plus}
          text="Add Content"
          iconProps={{ size: 20 }}
        />
      </div>
      <div className="d-flex align-items-center tabsList bottomLine mb-4">
        {['Audio', 'Video'].map((tabName, index) => (
          <button
            key={tabName}
            onClick={() => setActiveTab(index)}
            className={`tab fullLine ${index === activeTab ? 'active cursorDefault' : ''}`}
          >
            {tabName}
          </button>
        ))}
      </div>
      <div className={`previewActionBar underline pb-3 ${!previewMode ? 'hide' : ''}`}>
        <p className="previewModeLabel">Preview mode</p>
        <PrimaryButton onClick={() => setPreviewMode(false)} text="Save content" />
      </div>
      <div className={`d-flex align-items-center underline pb-3 ${previewMode ? 'hide' : ''}`}>
        <div className="d-flex align-items-center justify-content-end w-100">
          <Button onClick={() => setShowModalUploadContent(true)}>
            <IconImport fill="var(--catalina-blue)" size={14} />
            Import
          </Button>
        </div>
      </div>
      <Table
        // loading={loading}
        height={400}
        wordWrap="break-word"
        data={[]}
        // data={meditations}
        id="audio-content-table"
        // rowClassName="cursorPointer"
        // onRowClick={({ externalId }) => redirectToContentByContentId(externalId)}
      >
        <Column verticalAlign="middle" width={70} align="center">
          <HeaderCell className="headColumn">ID</HeaderCell>
          <Cell dataKey="externalId" />
        </Column>
        <Column verticalAlign="middle" width={80} align="center">
          <HeaderCell className="headColumn">Image</HeaderCell>
          <ImageCell dataKey="imageIcon" />
        </Column>

        <Column verticalAlign="middle" width={160}>
          <HeaderCell className="headColumn">VO Artist</HeaderCell>
          <Cell dataKey="voiceArtist" />
        </Column>

        <Column verticalAlign="middle" width={230}>
          <HeaderCell className="headColumn">Title</HeaderCell>
          <Cell dataKey="subtitle" />
        </Column>
        <Column verticalAlign="middle" minWidth={400} flexGrow={1}>
          <HeaderCell className="headColumn">Body Player</HeaderCell>
          <Cell dataKey="body" className="hideTwoRows" />
        </Column>
      </Table>
      <Pagination
        forcePage={selectedFeedPage - 1}
        total={100}
        lastPage={10}
        show={10 > 1}
        innerClassName="mt-sm-2"
        onPageChange={({ selected }) => setSelectedFeedPage(selected + 1)}
      />
      <ModalUploadThemeContent
        onUpload={(data, onHide) => {
          console.log(data)
          setPreviewMode(true)
          onHide()
        }}
        title="Upload new cotnent"
        show={showModalUploadContent}
        onHide={() => setShowModalUploadContent(false)}
      />
      <ModalThemeContent show={showModalContent} onHide={() => setShowModalContent(false)} />
    </div>
  )
}

export default ThemeContent
