import { useCallback, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { debounce, isEmpty, omitBy } from 'lodash'
import ReactPaginate from 'react-paginate'
import { Table } from 'rsuite'

import { SETTINGS_PAGINATION } from '../../../utils/constants'
import { FIELDS_FILTER_TAGS } from '../../../utils/constantsForms'
import { deleteTag, getAllTags } from '../../../api/admin/tags'
import { isNullOrEmpty } from '../../../utils/helpers'
import { Cross, IconFilter, Plus } from '../../../components/SvgIcon'
import { ActionCell } from '../../../components/Table'
import Tag from '../../../components/Tag'
import IconButton from '../../../components/IconButton'
import CustomInput from '../../../components/CustomInput'
import PrimaryButton from '../../../components/PrimaryButton'
import ModalFilter from '../../../components/Modals/ModalFilter'
import ModalConfirm from '../../../components/Modals/ModalConfirm'
import ModalManageTag from '../../../components/Modals/ModalManageTag'
import ToggleTagVisibleUser from './components/ToggleTagVisibleUser'

import './styles.css'

const Tags = () => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [filterData, setFilterData] = useState({})
  const searchInputRef = useRef(null)
  const queryClient = useQueryClient()
  const appliedFilterCount = useMemo(
    () => Object.keys(omitBy(filterData, isEmpty)).length,
    [filterData]
  )
  const onSearch = useCallback(
    debounce((e) => handleUpdateFilter({ search: e?.target?.value }), 500),
    []
  )

  const resetFilter = useCallback(() => {
    setSelectedPage(1)
    setFilterData({})
    searchInputRef.current.value = ''
  }, [])

  const [showModalManageTag, setShowModalManageTag] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const mutationDeleteTag = useMutation({
    mutationKey: ['deleteTag'],
    mutationFn: deleteTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] })
      setSearchParams({})
      setShowModalDelete(false)
    },
  })

  const onClickEditIcon = useCallback((tagInfo) => {
    setShowModalManageTag(true)
    setSearchParams({
      id: tagInfo.id,
      name: tagInfo.name,
      color: tagInfo.color,
      isVisibleToUser: tagInfo.isVisibleToUser,
    })
  }, [])
  const onClickDeleteIcon = useCallback((tagInfo) => {
    setShowModalDelete(true)
    setSearchParams({ id: tagInfo.id, name: tagInfo.name, color: tagInfo.color })
  }, [])

  const handleUpdateFilter = useCallback((data) => {
    setSelectedPage(1)
    setShowModalFilter(false)
    setFilterData((oldState) => {
      const newFilterData = { ...oldState, ...data }
      return omitBy(newFilterData, isNullOrEmpty)
    })
  }, [])

  const {
    data: tags,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['tags', selectedPage, filterData],
    placeholderData: keepPreviousData,
    queryFn: () => getAllTags({ ...filterData, page: selectedPage, size: 20 }),
  })

  return (
    <div className="containerAdminMainInfo">
      <div className="d-flex justify-content-between gap-2 align-items-center mb-3">
        <h1 className="titleAdminPage mb-0">Tag manager</h1>
        <PrimaryButton Icon={Plus} text="Create tag" onClick={() => setShowModalManageTag(true)} />
      </div>
      <div className="position-relative mb-4">
        <div className="innerDropDownFilter">
          <CustomInput
            inputRef={searchInputRef}
            onChange={onSearch}
            placeholder="Search by tag name"
            innerClassName="flex-grow-1"
          />
          <div className="d-flex align-items-center gap-3">
            <IconButton
              buttonClassName="p-0 bg-transparent rounded-0 position-relative overflow-visible"
              renderTooltipContent={() => <p>Show Filter</p>}
              placement="bottomEnd"
              onClick={() => setShowModalFilter(true)}
            >
              <IconFilter size={20} />
              {appliedFilterCount > 0 && (
                <span className="appliedFilterCount">{appliedFilterCount}</span>
              )}
            </IconButton>
            {appliedFilterCount > 0 && (
              <IconButton
                buttonClassName="p-0 bg-transparent rounded-0 position-relative"
                renderTooltipContent={() => <p>Reset filter</p>}
                placement="bottomEnd"
                onClick={resetFilter}
              >
                <Cross size={25} fill="var(--persian-red)" />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <Table
        loading={isLoading || isFetching}
        data={tags?.data?.data}
        shouldUpdateScroll={false}
        autoHeight
        rowHeight={55}
        id="table-tags"
      >
        <Table.Column width={50} align="center" verticalAlign="center">
          <Table.HeaderCell className="headColumn p-0">#</Table.HeaderCell>
          <Table.Cell style={{ padding: 0 }}>
            {(item) => (
              <ToggleTagVisibleUser
                {...item}
                isLoading={isLoading || isFetching}
                updateQueryKey={['tags', selectedPage, filterData]}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" flexGrow={2} minWidth={170}>
          <Table.HeaderCell dataKey="name" className="headColumn">
            Tag
          </Table.HeaderCell>
          <Table.Cell dataKey="name">
            {({ name, color }) => <Tag name={name} color={color} />}
          </Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" align="center" width={120}>
          <Table.HeaderCell className="headColumn">Themes</Table.HeaderCell>
          <Table.Cell>{({ themes }) => themes.length}</Table.Cell>
        </Table.Column>
        <Table.Column verticalAlign="middle" align="center" width={120}>
          <Table.HeaderCell className="headColumn">Users</Table.HeaderCell>
          <Table.Cell dataKey="usersCount" />
        </Table.Column>
        <Table.Column verticalAlign="middle" width={50}>
          <Table.HeaderCell className="headColumn" />
          <ActionCell
            iconSize={20}
            actions={[
              { label: 'Edit', key: 'edit', function: onClickEditIcon },
              { label: 'Delete', key: 'delete', function: onClickDeleteIcon },
            ]}
          />
        </Table.Column>
      </Table>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <p className="totalResult">{tags?.data?.total} Result</p>
        <ReactPaginate
          {...SETTINGS_PAGINATION}
          pageCount={tags?.data?.lastPage}
          marginPagesDisplayed={-1}
          pageRangeDisplayed={-1}
          forcePage={selectedPage - 1}
          containerClassName="d-flex align-items-stretch gap-2"
          onPageChange={({ selected }) => setSelectedPage(selected + 1)}
        />
      </div>
      <ModalManageTag
        show={showModalManageTag}
        onHide={() => setShowModalManageTag(false)}
        updateQueryKey={['tags', selectedPage, filterData]}
      />
      <ModalConfirm
        show={showModalDelete}
        onHide={() => {
          setShowModalDelete(false)
          setSearchParams({})
        }}
        customClassName="modalDeleteTag"
        text={
          <p>
            Are you sure you want to delete{' '}
            <Tag className="sm" name={searchParams.get('name')} color={searchParams.get('color')} />{' '}
            tag?
          </p>
        }
        textButton="Delete"
        handleClickButton={() => mutationDeleteTag.mutate(searchParams.get('id'))}
      />
      <ModalFilter
        show={showModalFilter}
        onReset={resetFilter}
        defaultValues={filterData}
        onHide={() => setShowModalFilter(false)}
        formFields={FIELDS_FILTER_TAGS}
        onSubmit={handleUpdateFilter}
      />
    </div>
  )
}

export default Tags
