import { useCallback, useMemo, useState } from 'react'
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { isEmpty, toNumber } from 'lodash'
import { Dropdown } from 'rsuite'

import {
  MESSAGE_TYPES,
  PIPELINE_TYPES,
  PIPELINE_TABLES_SETTINGS,
  DROPDOWN_PICKER_PIPELINES_DATA,
} from '../../../utils/constants'
import { getThemeDetails } from '../../../api/admin/themes'
import { FillSetting, IconUsers, ThList, ThreeDots } from '../../../components/SvgIcon'
import ModalTableContent from '../../../components/Modals/ModalTableContent'
import ModalDeliveryTime from '../../../components/Modals/ModalDeliveryTime'
import CustomDropdownPicker from '../../../components/CustomDropdownPicker'
import Pipeline from './components/Pipeline'

import './styles.css'

const Theme = ({ currentCompanyPermissions }) => {
  const navigation = useNavigate()
  const { themeId, programId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultMessageTypes = JSON.parse(searchParams.get('messageTypes')) ?? []

  const [selectedPipelineDeliveryTimeInfo, setSelectedPipelineDeliveryTimeInfo] = useState({})
  const [showModalMeditations, setShowModalMeditations] = useState(false)
  const [messageTypes, setMessageTypes] = useState(
    !isEmpty(defaultMessageTypes)
      ? defaultMessageTypes
      : [PIPELINE_TYPES.CONTENT + MESSAGE_TYPES.RELATIVE]
  )
  const scrollToMessageTable = useCallback(({ value }) => {
    setTimeout(() => {
      const element = document.getElementById(`table-${value}`)
      if (!element) return
      window.scroll({
        top: element.offsetTop,
        behavior: 'smooth',
      })
    }, 500)
  }, [])

  const updateVisibleTables = useCallback((messageTypes) => {
    setMessageTypes(messageTypes)
    setSearchParams({ messageTypes: JSON.stringify(messageTypes) })
  }, [])

  const { data: theme } = useQuery({
    queryKey: ['theme', themeId],
    queryFn: () => getThemeDetails(themeId),
  })

  const pipelinesDropdownData = useMemo(
    () =>
      DROPDOWN_PICKER_PIPELINES_DATA.reduce(
        (acc, item) => (item.hasAccess(theme?.data) ? [...acc, item] : acc),
        []
      ),
    [theme]
  )

  return (
    <div>
      <div className="themeContent containerAdminMainInfo">
        <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
          <h1 className="titleAdminPage mb-0">{theme?.data.name ?? ''}</h1>
          <Dropdown
            renderToggle={(props) => <ThreeDots size={25} {...props} />}
            placement="bottomEnd"
            style={{ zIndex: 10 }}
          >
            <Dropdown.Item
              onClick={() => navigation(`/admin/programs/${programId}/${themeId}/settings`)}
              className="d-flex align-items-center gap-2"
              icon={<FillSetting size={17} fill="#969898" />}
            >
              Settings
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                const searchParams = {
                  filterData: JSON.stringify({ themeId: [toNumber(themeId)] }),
                }
                navigation({
                  pathname: '/admin/users',
                  search: '?' + createSearchParams(searchParams).toString(),
                })
              }}
              className="d-flex align-items-center gap-2"
              icon={<IconUsers size={17} fill="#969898" />}
            >
              Users
            </Dropdown.Item>
            {currentCompanyPermissions?.useContent && (
              <Dropdown.Item
                onClick={() => setShowModalMeditations(true)}
                className="d-flex align-items-center gap-2"
                icon={<ThList size={17} fill="#969898" />}
              >
                Contents
              </Dropdown.Item>
            )}
          </Dropdown>
        </div>
        <CustomDropdownPicker
          block
          innerClassName="mw-100 w-100 innerDropdownPipelines"
          placeholder="Select type of pipeline to display"
          groupBy="globalType"
          onChange={updateVisibleTables}
          value={messageTypes}
          onSelect={(_, selectedValue) => scrollToMessageTable(selectedValue)}
          dataDropdown={pipelinesDropdownData}
          size="lg"
          labelKey="title"
          valueKey="value"
        />
        <div>
          {messageTypes.map((tableKey, index) => (
            <Pipeline
              key={index}
              {...PIPELINE_TABLES_SETTINGS[tableKey]}
              tableKey={tableKey}
              onClickIconTime={setSelectedPipelineDeliveryTimeInfo}
            />
          ))}
        </div>
      </div>

      <ModalTableContent
        themeId={themeId}
        show={showModalMeditations}
        onHide={() => setShowModalMeditations(false)}
      />
      <ModalDeliveryTime
        themeId={themeId}
        show={!isEmpty(selectedPipelineDeliveryTimeInfo)}
        onHide={() => setSelectedPipelineDeliveryTimeInfo({})}
        pipelineType={selectedPipelineDeliveryTimeInfo?.type}
      />
    </div>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  currentCompanyPermissions: currentCompany.permissions,
})

export default connect(mapStateToProps)(Theme)
