import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { connect } from 'react-redux'
import { isEmpty, omitBy } from 'lodash'

import { INPUT_TYPES, URL_PATTERN } from '../../../../utils/constants'
import { changeInfoCurrentCompany } from '../../../../api/admin/company'
import { updateCurrentCompany } from '../../../../redux/actions/currentCompany'
import { formatPhoneNumber, isNullOrEmpty } from '../../../../utils/helpers'
import useValidatingForm from '../../../../utils/hooks/useValidatingForm'
import { Cross, FillSetting, IconPhone, Plus } from '../../../../components/SvgIcon'
import ControllerField from '../../../../components/HookForm/components/ControllerField'
import PrimaryButton from '../../../../components/PrimaryButton'
import ImageLoader from '../../../../components/ImageLoader'

const ContactPreview = ({ customLinks, avatarUrl, name, phone }) => {
  return (
    <div className="previewContact position-relative">
      <ImageLoader
        src={avatarUrl}
        loadingProps={{ size: 15 }}
        alt="Logo daydrop"
        imageClassName="logo"
      />
      <p className="name mt-2 mb-3">{name || '---'}</p>
      <div className="blockInfo w-100">
        <p className="blockInfoTitle sm ">Contact info</p>
        <p className="blockInfoContent mw-800">
          <IconPhone size={17} fill="var(--catalina-blue)" />
          <span className="ms-2">{formatPhoneNumber(phone) || '---'}</span>
        </p>
      </div>
      <div className="blockInfo w-100 mt-3">
        <p className="blockInfoTitle sm mb-3">URL's</p>
        <p className="blockInfoContent mw-800">
          <ul className="listContactList">
            {customLinks?.map(({ label, url }, index) => (
              <li key={label + url + index}>
                <p className="labelLink">{label || '---'}</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                  href={URL_PATTERN.test(url) ? url : '#'}
                >
                  {url}
                </a>
              </li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  )
}

const AccountDetails = ({
  isActiveTab,
  currentCompanyInfo,
  updateCurrentCompany,
  currentUserId,
}) => {
  const queryClient = useQueryClient()
  const { handleSubmit, control, formState, watch, reset } = useForm({
    defaultValues: {
      ...currentCompanyInfo,
      bigLogo: { imgUrl: currentCompanyInfo?.bigLogo, file: null },
      smallLogo: { imgUrl: currentCompanyInfo?.smallLogo, file: null },
      customLinks: !isEmpty(currentCompanyInfo.customLinks)
        ? currentCompanyInfo.customLinks
        : [{ label: '', url: '' }],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customLinks',
  })
  const formIsValid = useValidatingForm(formState)

  const mutationUpdateCompanyInfo = useMutation({
    mutationKey: ['updateCompanyInfo', currentCompanyInfo.id],
    mutationFn: (submitFormData) => {
      const customLinks = submitFormData.customLinks.filter(({ label, url }) => label && url)
      const formData = {
        ...submitFormData,
        customLinks,
        bigLogo: submitFormData.bigLogo.file,
        smallLogo: submitFormData.smallLogo.file,
      }
      const formattedData = omitBy(formData, isNullOrEmpty)

      return changeInfoCurrentCompany(formattedData)
    },
    onSuccess: ({ data: responseData }, formData) => {
      reset({}, { keepValues: true, keepDirty: false })
      updateCurrentCompany({ ...formData, ...responseData })
      queryClient.setQueryData(['adminCompanies', currentUserId], (oldInfo) => {
        const newData = oldInfo.data?.map((companyInfo) =>
          currentCompanyInfo?.id === companyInfo.id
            ? { ...companyInfo, ...responseData }
            : companyInfo
        )

        return { ...oldInfo, data: newData }
      })
    },
  })

  return (
    <div className={`tabBlock ${isActiveTab ? 'visibleTabBlock' : ''}`}>
      <div className="blockInfo mb-5">
        <p className="blockInfoTitle">Set Up Your Organization</p>
        <p className="blockInfoContent mw-800">
          Provide key details about your organization, including its name, logo, and branding
          elements, to ensure a consistent experience across the platform.
        </p>
      </div>
      <div className="d-flex align-items-start ">
        <form
          className="formOrganizationInfo"
          onSubmit={handleSubmit(mutationUpdateCompanyInfo.mutate)}
        >
          <div className="d-flex align-items-end gap-4 mb-4">
            <ControllerField
              name="bigLogo"
              control={control}
              rules={{ required: true, validate: (value) => !isEmpty(value.imgUrl) }}
              label="Big logo*"
              innerClassName="w-auto"
              dropzoneClassName="dropzoneBigLogo"
              type={INPUT_TYPES.INPUT_FILE}
            />
            <ControllerField
              name="smallLogo"
              control={control}
              rules={{ required: true, validate: (value) => !isEmpty(value.imgUrl) }}
              label="Small logo*"
              innerClassName="w-auto"
              dropzoneClassName="dropzoneSmallLogo"
              type={INPUT_TYPES.INPUT_FILE}
            />
          </div>
          <ControllerField
            name="name"
            control={control}
            placeholder="Company"
            rules={{ required: true }}
            label="Organization name*"
          />
          <ControllerField
            name="phone"
            control={control}
            disabled
            innerClassName="mt-3 mb-4"
            rules={{ required: false }}
            label="Phone number*"
            placeholder="+1 (999) 999-9999"
            type={INPUT_TYPES.INPUT_MASK}
          />
          <div>
            <label className="inputLabel mb-2 ms-0">Contact URL's</label>
            {fields.map((field, index) => (
              <div key={field.id}>
                <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
                  <ControllerField
                    name={`customLinks[${index}].label`}
                    control={control}
                    innerClassName="innerInputLabelContactLink"
                    defaultValue=""
                    placeholder="Label"
                    rules={{ required: false }}
                  />
                  {fields.length > 1 && (
                    <Cross
                      onClick={() => {
                        remove(index)
                      }}
                      className="cursorPointer"
                      fill="var(--persian-red)"
                      size={17}
                    />
                  )}
                </div>
                <ControllerField
                  name={`customLinks[${index}].url`}
                  control={control}
                  innerClassName="mb-2"
                  placeholder="https://www.example.com"
                  rules={{ required: false, pattern: URL_PATTERN }}
                />
              </div>
            ))}
            {fields.length < 5 && (
              <button
                type="button"
                onClick={() => append({ label: '', url: '' })}
                className="defaultButton ms-auto"
              >
                <Plus size={15} /> Add URL
              </button>
            )}
          </div>
          <PrimaryButton
            disabled={!formIsValid || mutationUpdateCompanyInfo.isPending}
            customClass="big mw-100 mt-4 w-100"
            text="Save"
          />
        </form>
        <ContactPreview
          name={watch('name')}
          phone={watch('phone')}
          customLinks={watch('customLinks')}
          avatarUrl={watch('smallLogo')?.imgUrl}
        />
      </div>
      <FillSetting className="businessSettingsIconBG" />
    </div>
  )
}

const mapStateToProps = ({ currentCompany, user }) => ({
  currentCompanyInfo: currentCompany,
  currentUserId: user.data.id,
})
const mapDispatchToProps = {
  updateCurrentCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
