import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { omitBy } from 'lodash'

import { FIELDS_FILTER_DASHBOARD } from '../../../utils/constantsForms'
import { isNullOrEmpty } from '../../../utils/helpers'
import { IconFilter } from '../../../components/SvgIcon'
import ModalFilter from '../../../components/Modals/ModalFilter'
import IconButton from '../../../components/IconButton'
import LineChart from '../../../components/DashboardCharts/LineChart'
import ChartThemeStatistics from '../../../components/ChartThemeStatistics'
import ContentRating from '../../../components/DashboardCharts/ContentRating'
import SubscriptionList from './components/SubscriptionList'

import './styles.css'

const Dashboard = ({ companyPermissions }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [filterData, setFilterData] = useState(JSON.parse(searchParams?.get('filterData')) ?? {})
  const appliedFilterCount = useMemo(() => Object.keys(filterData).length, [filterData])

  const handleUpdateFilter = useCallback((data) => {
    setShowModalFilter(false)
    setFilterData((oldState) => {
      const newFilterData = { ...oldState, ...data }
      const filteredData = omitBy(newFilterData, isNullOrEmpty)

      setSearchParams({ filterData: JSON.stringify(filteredData), selectedPage: 1 })

      return filteredData
    })
  }, [])
  const resetFilter = useCallback(() => {
    setFilterData({})
    setSearchParams({})
    setShowModalFilter(false)
  }, [])

  return (
    <div className="containerAdminMainInfo">
      <div className="d-flex align-items-center gap-3 mb-2 justify-content-between">
        <h1 className="titleAdminPage mb-0">Dashboard</h1>
        <IconButton
          buttonClassName="p-0 bg-transparent rounded-0 position-relative overflow-visible"
          renderTooltipContent={() => <p>Show Filter</p>}
          placement="bottomEnd"
          onClick={() => setShowModalFilter(true)}
        >
          <IconFilter size={20} />
          {appliedFilterCount > 0 && (
            <span className="appliedFilterCount">{appliedFilterCount}</span>
          )}
        </IconButton>
      </div>
      <LineChart filterData={filterData} />
      <SubscriptionList filterData={filterData} />
      {companyPermissions?.getDashboard && (
        <>
          <ContentRating filterData={filterData} />
          <ChartThemeStatistics filterData={filterData} />
        </>
      )}
      <ModalFilter
        show={showModalFilter}
        onReset={resetFilter}
        defaultValues={filterData}
        formFields={FIELDS_FILTER_DASHBOARD}
        onHide={() => setShowModalFilter(false)}
        onSubmit={handleUpdateFilter}
      />
    </div>
  )
}

const mapStateToProps = ({ currentCompany }) => ({
  companyPermissions: currentCompany.permissions,
})

export default connect(mapStateToProps)(Dashboard)
