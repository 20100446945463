import { useCallback, useMemo, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toNumber } from 'lodash'
import { Dropdown } from 'rsuite'

import { routes } from '../../../router/routes'
import { PRICE_TYPES_SETTINGS } from '../../../utils/constants'
import { getThemeDetails } from '../../../api/admin/themes'
import {
  LoadingIcon,
  Settings,
  MessageAltDetail,
  Copy,
  Analytics,
  ThreeDots,
} from '../../../components/SvgIcon'
import Button from '../../../components/Button'
import IconButton from '../../../components/IconButton'
import ModalUpdateTheme from '../../../components/Modals/ModalTheme'
import ModalDuplicateTheme from '../../../components/Modals/ModalDuplicateTheme'
import ModalHardcodeMessage from '../../../components/Modals/ModalHardcodeMessage'
import ModalHubspotIntegration from '../../../components/Modals/ModalHubspotIntegration'
import ToggleActivateTheme from './components/ToggleActivateTheme'
import ThemeTags from './components/ThemeTags'
import UploadUserFile from './components/UploadUserFile'

import './styles.css'
import EmergencyContentPicker from './components/EmergencyContentPicker'

const ThemeSettings = () => {
  const [showModalDuplicate, setShowModalDuplicate] = useState(false)
  const [showModalUpdateTheme, setShowModalUpdateTheme] = useState(false)
  const [showHardcodeMessages, setShowHardcodeMessages] = useState(false)
  const [showModalHubspotIntegration, setShowModalHubspotIntegration] = useState(false)
  const navigation = useNavigate()
  const { themeId } = useParams()
  const queryClient = useQueryClient()

  const navigateToThemeAnalytic = useCallback(() => {
    const searchParams = { filterData: JSON.stringify({ themeId: toNumber(themeId) }) }
    navigation({
      pathname: routes.dashboard,
      search: '?' + createSearchParams(searchParams).toString(),
    })
  }, [themeId])

  const handleSuccessUpdate = useCallback((newData) => {
    queryClient.setQueryData(['themeInfo', themeId], ({ data }) => {
      return {
        data: {
          ...data,
          name: newData.name,
          activePrice: {
            ...data.activePrice,
            price: newData.priceAmount,
            type: newData.priceType,
            extensionDays: newData.priceExtensionDays,
          },
        },
      }
    })
    setShowModalUpdateTheme(false)
  }, [])

  const { data: theme, isLoading: isLoadingThemeData } = useQuery({
    queryKey: ['themeInfo', themeId],
    queryFn: () => getThemeDetails(themeId),
  })
  const price = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(theme?.data?.activePrice?.price || ''),
    [theme]
  )

  return (
    <div className="themeSettings containerAdminMainInfo">
      <div className="d-flex gap-4 align-items-center justify-content-between mb-5">
        <div className="d-flex gap-3 align-items-center">
          <div className="iconSettings">
            <Settings size={25} />
          </div>
          <div>
            <h1 className="titleAdminPage mb-1 fw-bold">{theme?.data?.name}</h1>
            <p className="subscriptionPrice">
              {price} USD •{' '}
              <span>{PRICE_TYPES_SETTINGS[theme?.data?.activePrice?.type]?.label}</span>
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <ToggleActivateTheme
            isPublished={theme?.data?.isPublished}
            priceInfo={theme?.data?.activePrice?.price}
          />
          <Button onClick={() => setShowModalUpdateTheme(true)} customClass="border-1">
            Edit theme
          </Button>
          <Dropdown
            renderToggle={(props, ref) => (
              <IconButton
                renderTooltipContent={() => <p>Settings</p>}
                placement="autoVerticalEnd"
                buttonClassName="border-1 btnThemeSettingsDropdownMenu defaultButton"
                ref={ref}
                disabledTooltip
                {...props}
              >
                <ThreeDots className="threeDotsHorizontal" size={18} fill="var(--catalina-blue)" />
              </IconButton>
            )}
            placement="bottomEnd"
          >
            <Dropdown.Item
              icon={<Copy size={17} fill="#969898" />}
              className="d-flex align-items-center gap-2"
              onClick={() => setShowModalDuplicate(true)}
            >
              Duplicate
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center gap-2"
              onClick={navigateToThemeAnalytic}
              icon={<Analytics size={17} color="#969898" />}
            >
              Statistics
            </Dropdown.Item>
            <Dropdown.Item
              className="d-flex align-items-center gap-2"
              onClick={() => setShowHardcodeMessages(true)}
              icon={<MessageAltDetail size={17} fill="#969898" />}
            >
              Hardcoded Messages
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <EmergencyContentPicker
        themeType={theme?.data.activePrice.type}
        defaultEmergencyContentId={theme?.data?.emergencyContentId}
      />
      <ThemeTags />
      <UploadUserFile isPublished={theme?.data?.isPublished} />
      <div>
        <p className="blockTitle underline mt-5 pb-3 mb-3">Integrations</p>
        <div>
          <button className="integrationBtn" onClick={() => setShowModalHubspotIntegration(true)}>
            <img src="/images/hubspotIcon.webp" alt="Hubspot Integration" />
            <span>HubSpot Integration</span>
          </button>
        </div>
      </div>
      <ModalHardcodeMessage
        show={showHardcodeMessages}
        onHide={() => setShowHardcodeMessages(false)}
      />
      <ModalDuplicateTheme
        show={showModalDuplicate}
        onHide={() => setShowModalDuplicate(false)}
        {...theme?.data}
      />
      {isLoadingThemeData && (
        <div className="innerLoadingAdminContent">
          <LoadingIcon size={60} />
        </div>
      )}
      <ModalHubspotIntegration
        themeId={themeId}
        show={showModalHubspotIntegration}
        onHide={() => setShowModalHubspotIntegration(false)}
      />
      <ModalUpdateTheme
        themeId={themeId}
        title="Update theme"
        show={showModalUpdateTheme}
        defaultValues={{
          name: theme?.data?.name,
          priceType: theme?.data?.activePrice?.type,
          priceAmount: theme?.data?.activePrice?.price,
          priceExtensionDays: theme?.data?.activePrice?.extensionDays,
        }}
        onSuccessUpdate={(_, newData) => handleSuccessUpdate(newData)}
        onHide={() => setShowModalUpdateTheme(false)}
      />
    </div>
  )
}

export default ThemeSettings
