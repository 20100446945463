import { useMemo } from 'react'
import { connect } from 'react-redux'

import Page404 from '../pages/Page404'

const RenderLayout = ({ Layout, roles, userRole }) => {
  const userHasPermission = useMemo(() => roles.includes(userRole), [userRole])

  return userHasPermission ? <Layout /> : <Page404 />
}

export default connect(({ user }) => ({
  userRole: user.role,
}))(RenderLayout)
