import { isEmpty, isNil } from 'lodash'
import { Tooltip, Whisper } from 'rsuite'

import { EXCEPT_TYPE_IMAGE } from '../../utils/constants'
import { Info, Plus } from '../SvgIcon'

import './styles.css'

const CustomInputFile = ({
  label,
  showIconInfo = false,
  infoText = '',
  error,
  onChange,
  value = '',
  innerClassName = '',
  dropzoneClassName = '',
  acceptTypes = EXCEPT_TYPE_IMAGE,
}) => {
  const newFile = (e) => {
    const image = e.target?.files?.[0]
    if (!isNil(image)) {
      const imgUrl = URL.createObjectURL(image)
      onChange({ file: image, imgUrl })
    }
  }

  return (
    <div className={`innerInputFile ${innerClassName}`}>
      {!isEmpty(label) && (
        <div className="d-flex align-items-center gap-1 inputLabel">
          <label>{label}</label>
          {showIconInfo && (
            <Whisper
              placement="top"
              trigger="hover"
              speaker={
                <Tooltip>
                  <p>{infoText}</p>
                </Tooltip>
              }
            >
              <span className="d-flex">
                <Info fill="var(--darkest-gray)" size={15} />
              </span>
            </Whisper>
          )}
        </div>
      )}
      <div style={error ? { borderColor: 'red' } : {}} className={`dropZone ${dropzoneClassName}`}>
        {!isNil(value?.imgUrl) ? <img className="filePreview" src={value?.imgUrl} alt="" /> : null}
        <div style={{ opacity: !isNil(value?.imgUrl) ? 0 : 1 }} className="wrapperBgHover">
          <Plus className="innerAddBtnImage" />
        </div>
        <input id onChange={newFile} className="inputFile" type="file" accept={acceptTypes} />
      </div>
    </div>
  )
}

export default CustomInputFile
