import { isNil } from 'lodash'

import useTimer from '../../../../utils/hooks/useTimer'
import PrimaryButton from '../../../../components/PrimaryButton'

const Footer = ({ step, btnText, onClickResendCode, btnDisabled }) => {
  const { onChangeTimerTime, timer } = useTimer()

  const handleClickResendCode = async () => {
    await onClickResendCode()
    onChangeTimerTime(60)
  }

  return (
    <div className="innerFooter">
      {step === 2 && (
        <p className="text-center mt-2">
          Didn't receive code?{' '}
          {isNil(timer) ? (
            <button
              type="button"
              onClick={handleClickResendCode}
              disabled={btnDisabled}
              className="cursorPointer fw-bold p-0 bg-transparent"
            >
              <u>Click here</u>
            </button>
          ) : (
            <b>{timer}</b>
          )}
        </p>
      )}
      <PrimaryButton disabled={btnDisabled} customClass="big buttonRegistration" text={btnText} />
    </div>
  )
}

export default Footer
