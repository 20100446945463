import { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { camelCase, debounce, isEmpty, isNil, omitBy, snakeCase, sum } from 'lodash'
import { Table } from 'rsuite'
import ReactPaginate from 'react-paginate'

import {
  DATE_FORMAT_MDY,
  SETTINGS_PAGINATION,
  STATUS_CELL_TYPES,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUSES_INFO,
} from '../../../utils/constants'
import { FIELDS_FILTER_PROGRAM_THEMES } from '../../../utils/constantsForms'
import { getProgramInfo } from '../../../api/admin/programs'
import { getProgramThemes } from '../../../api/admin/themes'
import { Cross, IconFilter, Plus } from '../../../components/SvgIcon'
import { DateCell, StatusCell } from '../../../components/Table'
import ModalFilter from '../../../components/Modals/ModalFilter'
import CustomInput from '../../../components/CustomInput'
import IconButton from '../../../components/IconButton'
import PrimaryButton from '../../../components/PrimaryButton'
import TextTooltipCell from '../../../components/Table/components/TextTooltipCell'
import ModalCreateTheme from '../../../components/Modals/ModalTheme'

const ProgramThemes = () => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [filterData, setFilterData] = useState({})
  const [orderFilterData, setOrderFilterData] = useState({})
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [showModalCreateTheme, setShowModalCreateTheme] = useState(false)
  const searchInputRef = useRef(null)
  const { programId } = useParams()
  const navigation = useNavigate()
  const appliedFilterCount = useMemo(() => Object.keys(filterData).length, [filterData])

  const handleSearch = useCallback(
    debounce((value) => handleUpdateFilter({ search: value }), 500),
    []
  )

  const handleOrderColumn = useCallback((sortColumn, sortType) => {
    setOrderFilterData({ orderBy: snakeCase(sortColumn), orderDirection: sortType })
  }, [])

  const resetFilter = useCallback(() => {
    searchInputRef.current.value = ''
    setFilterData({})
    setOrderFilterData({})
    setShowModalFilter(false)
  }, [])

  const handleUpdateFilter = useCallback((data) => {
    setSelectedPage(1)
    setShowModalFilter(false)
    setFilterData((oldState) => {
      const newFilterData = { ...oldState, ...data }
      return omitBy(
        newFilterData,
        (value) => isNil(value) || (isEmpty(value) && typeof value !== 'boolean')
      )
    })
  }, [])

  const { data: program } = useQuery({
    queryKey: ['programs', programId],
    queryFn: () => getProgramInfo(programId),
  })

  const { data: programThemes, isFetching: isFetchingProgramThemes } = useQuery({
    queryKey: ['programThemes', programId, filterData, selectedPage, orderFilterData],
    queryFn: () =>
      getProgramThemes({
        id: programId,
        page: selectedPage,
        size: 10,
        ...orderFilterData,
        ...filterData,
      }),
    select: (programThemes) => {
      const newProgramThemesData = programThemes.data.data.map((item) => ({
        ...item,
        totalSubscriptions: sum(Object.values(item.subscriptions)),
        totalMessages: sum(Object.values(item.pipelineItems)),
      }))

      return { ...programThemes, data: { ...programThemes.data, data: newProgramThemesData } }
    },
    placeholderData: keepPreviousData,
  })

  return (
    <div>
      <div className="containerAdminMainInfo">
        <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
          <h1 className="titleAdminPage mb-0">{program?.data.name ?? ''} themes</h1>
          <PrimaryButton
            Icon={Plus}
            text="Create Theme"
            onClick={() => setShowModalCreateTheme(true)}
          />
        </div>
        <div className="innerDropDownFilter mb-4">
          <CustomInput
            inputRef={searchInputRef}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search by name"
            innerClassName="innerUserSearchInput"
          />
          <div className="d-flex align-items-center gap-3">
            <IconButton
              buttonClassName="p-0 bg-transparent rounded-0 position-relative overflow-visible"
              renderTooltipContent={() => <p>Show Filter</p>}
              placement="bottomEnd"
              onClick={() => setShowModalFilter(true)}
            >
              <IconFilter size={20} />
              {appliedFilterCount > 0 && (
                <span className="appliedFilterCount">{appliedFilterCount}</span>
              )}
            </IconButton>
            {appliedFilterCount > 0 || !isEmpty(orderFilterData) ? (
              <IconButton
                buttonClassName="p-0 bg-transparent rounded-0 position-relative"
                renderTooltipContent={() => <p>Reset filter</p>}
                placement="bottomEnd"
                onClick={resetFilter}
              >
                <Cross size={25} fill="var(--persian-red)" />
              </IconButton>
            ) : null}
          </div>
        </div>

        <Table
          loading={isFetchingProgramThemes}
          wordWrap="break-word"
          data={programThemes?.data?.data}
          id="table-program-themes"
          autoHeight
          rowClassName="cursorPointer"
          onSortColumn={handleOrderColumn}
          sortColumn={camelCase(orderFilterData.orderBy)}
          sortType={orderFilterData.orderDirection}
          onRowClick={({ id }) => navigation(`${id}`)}
        >
          <Table.Column sortable verticalAlign="middle" minWidth={150} flexGrow={1}>
            <Table.HeaderCell className="headColumn">Name</Table.HeaderCell>
            <Table.Cell dataKey="name" />
          </Table.Column>

          <Table.Column sortable verticalAlign="middle" align="center" width={150}>
            <Table.HeaderCell className="headColumn">Total Users</Table.HeaderCell>
            <TextTooltipCell
              dataKey="totalSubscriptions"
              tooltipList={[
                {
                  label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.TRIAL].text,
                  key: 'subscriptions.trial',
                },
                {
                  label: 'Trial (without renew)',
                  key: 'subscriptions.nonRenewTrial',
                },
                {
                  label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.ACTIVE].text,
                  key: 'subscriptions.active',
                },
                {
                  label: 'Active (without renew)',
                  key: 'subscriptions.nonRenewActive',
                },
                {
                  label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.PAUSED].text,
                  key: 'subscriptions.paused',
                },
                {
                  label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.FINISHED].text,
                  key: 'subscriptions.finished',
                },
                {
                  label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.CANCELED].text,
                  key: 'subscriptions.canceled',
                },
                {
                  label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.PAYMENT_FAILED].text,
                  key: 'subscriptions.paymentFailed',
                },
                {
                  label: SUBSCRIPTION_STATUSES_INFO[SUBSCRIPTION_STATUSES.ABANDONED_CART].text,
                  key: 'subscriptions.abandonedCart',
                },
              ]}
            />
          </Table.Column>

          <Table.Column verticalAlign="middle" align="center" width={150}>
            <Table.HeaderCell className="headColumn">Price</Table.HeaderCell>
            <Table.Cell dataKey="price" />
          </Table.Column>
          <Table.Column verticalAlign="middle" align="center" width={150}>
            <Table.HeaderCell className="headColumn">Messages</Table.HeaderCell>
            <TextTooltipCell
              dataKey="totalMessages"
              tooltipList={[
                { label: 'Content', key: 'pipelineItems.content' },
                { label: 'Admin', key: 'pipelineItems.admin' },
                { label: 'Abandoned cart', key: 'pipelineItems.abandonedCart' },
              ]}
            />
          </Table.Column>
          <Table.Column sortable verticalAlign="middle" align="center" width={150}>
            <Table.HeaderCell className="headColumn">Status</Table.HeaderCell>
            <StatusCell dataKey="isPublished" statusType={STATUS_CELL_TYPES.THEME} />
          </Table.Column>
          <Table.Column sortable verticalAlign="middle" align="center" width={150}>
            <Table.HeaderCell className="headColumn">Created at</Table.HeaderCell>
            <DateCell dataKey="createdAt" dateFormat={DATE_FORMAT_MDY} />
          </Table.Column>
        </Table>
        <div className="d-flex align-items-center justify-content-between mt-3">
          <p className="totalResult">{programThemes?.data?.total} Result</p>
          <ReactPaginate
            {...SETTINGS_PAGINATION}
            pageCount={programThemes?.data?.lastPage}
            marginPagesDisplayed={-1}
            pageRangeDisplayed={-1}
            containerClassName="d-flex align-items-stretch gap-2"
            onPageChange={({ selected }) => setSelectedPage(selected + 1)}
          />
        </div>
      </div>
      <ModalFilter
        formFields={FIELDS_FILTER_PROGRAM_THEMES}
        show={showModalFilter}
        onReset={resetFilter}
        defaultValues={filterData}
        onSubmit={handleUpdateFilter}
        onHide={() => setShowModalFilter(false)}
      />
      <ModalCreateTheme
        title="Create theme"
        show={showModalCreateTheme}
        onHide={() => setShowModalCreateTheme(false)}
      />
    </div>
  )
}

export default ProgramThemes
