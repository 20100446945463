import { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { connect } from 'react-redux'

import { getAllTags } from '../../../../api/tags'
import { getUserTags, updateUserInfo } from '../../../../api/user'
import CustomTag from '../../../../components/CustomTag'
import Pagination from '../../../../components/Pagination'

const TabInterests = ({ isActiveBlock, userId }) => {
  const [selectedPage, setSelectedPage] = useState(1)

  const queryClient = useQueryClient()
  const { data: allInterests } = useQuery({
    queryKey: ['interests'],
    queryFn: () => getAllTags({ page: selectedPage, size: 1, withPagination: true }),
    enabled: isActiveBlock,
  })
  const { data: userInterests } = useQuery({
    queryKey: ['userInterests', userId],
    queryFn: getUserTags,
    enabled: isActiveBlock,
  })
  const userInterestsIds = useMemo(
    () => userInterests?.data?.map((interest) => interest.id),
    [userInterests]
  )

  const mutationUpdateUserInterests = useMutation({
    mutationKey: ['updateUserDetails', userId],
    mutationFn: ({ selected, selectedInterestsInfo }) => {
      const tagsIds = selected
        ? userInterestsIds?.filter((interestsId) => interestsId === selectedInterestsInfo?.id)
        : [...userInterestsIds, selectedInterestsInfo?.id]

      return updateUserInfo({ tagsIds })
    },
    onSuccess: (_, { selected, selectedInterestsInfo }) => {
      queryClient.setQueryData(['userInterests', userId], ({ data: oldInfo }) => {
        const newData = [...oldInfo, selectedInterestsInfo].reduce(
          (acc, interestsInfo) =>
            selectedInterestsInfo.id === interestsInfo.id && selected
              ? acc
              : [...acc, interestsInfo],
          []
        )
        return { ...oldInfo, data: newData }
      })
    },
  })

  return (
    <div className={`innerTabInterests tabBlock ${isActiveBlock ? 'visibleTabBlock' : ''}`}>
      <p className="blockDescription mb-md-3">
        Choose your interests to personalize your experience.
      </p>
      <div className="interests">
        {allInterests?.data.map((item) => {
          const isSelected = userInterestsIds?.some((id) => id === item?.id)

          return (
            <CustomTag
              onClick={() =>
                mutationUpdateUserInterests.mutate({
                  selected: isSelected,
                  selectedInterestsInfo: item,
                })
              }
              tagInfo={item}
              selected={isSelected}
              key={item?.id}
            />
          )
        })}
      </div>
      <Pagination
        forcePage={selectedPage - 1}
        total={allInterests?.data?.total}
        lastPage={allInterests?.data?.lastPage}
        show={allInterests?.data?.lastPage > 1}
        onPageChange={({ selected }) => setSelectedPage(selected + 1)}
      />
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  userId: user.data.id,
})

export default connect(mapStateToProps)(TabInterests)
