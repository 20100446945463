import zxcvbn from 'zxcvbn'

import {
  INPUT_TYPES,
  EMAIL_PATTERN_STR,
  PHONE_PATTERN_STR,
  LANDLINE_PHONE_MASK,
  LANDLINE_PATTERN_STR,
} from '../../../utils/constants'

export const createFormFields = (formRef) => [
  {
    name: 'name',
    innerClassName: 'innerInputRegistration borderLine',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Organization name*',
    placeholder: '',
    rules: {
      required: true,
    },
    step: 1,
  },
  {
    name: 'firstName',
    innerClassName: 'innerInputRegistration firstOneLine',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'First name*',
    placeholder: '',
    rules: {
      required: true,
    },
    step: 1,
  },
  {
    name: 'lastName',
    innerClassName: 'innerInputRegistration secondOneLine',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Last name*',
    placeholder: '',
    rules: {
      required: true,
    },
    step: 1,
  },
  {
    name: 'email',
    innerClassName: 'innerInputRegistration',
    inputType: 'email',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Email*',
    placeholder: '',
    rules: {
      required: true,
      pattern: { value: EMAIL_PATTERN_STR },
    },
    step: 1,
  },
  {
    name: 'phone',
    type: INPUT_TYPES.INPUT_MASK,
    inputType: 'tel',
    defaultValue: '',
    innerClassName: 'innerInputRegistration firstOneLine',
    label: 'Mobile',
    placeholder: '+1 (999) 999-9999',
    rules: {
      required: false,
      pattern: {
        value: PHONE_PATTERN_STR,
        message: 'Not valid number',
      },
    },
    step: 1,
  },
  {
    name: 'landline',
    type: INPUT_TYPES.INPUT_MASK,
    inputType: 'tel',
    defaultValue: '',
    innerClassName: 'innerInputRegistration secondOneLine',
    label: 'Office',
    placeholder: '(999) 999-9999',
    mask: LANDLINE_PHONE_MASK,
    rules: {
      required: false,
      pattern: {
        value: LANDLINE_PATTERN_STR,
        message: 'Not valid number',
      },
    },
    step: 1,
  },
  {
    name: 'password',
    innerClassName: 'innerInputRegistration password',
    inputType: 'password',
    showPasswordStrength: true,
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Password*',
    placeholder: '',
    rules: {
      required: true,
      validate: (value) => zxcvbn(value).score >= 2,
    },
    step: 1,
  },
  {
    name: 'confirmPassword',
    innerClassName: 'innerInputRegistration',
    inputType: 'password',
    type: INPUT_TYPES.TEXT,
    defaultValue: '',
    label: 'Confirm password',
    placeholder: '',
    rules: {
      required: true,
      validate: (value) => value === formRef.current.getValues('password'),
    },
    step: 1,
  },

  {
    name: 'code',
    innerClassName: 'innerInputRegistration otpCode hide',
    placeholder: '',
    type: INPUT_TYPES.OTP,
    defaultValue: '',
    rules: {
      required: false,
      minLength: 6,
    },
    isInputNum: true,
    maxCountAutoSubmit: 1,
    onSuccess: () => formRef.current?.submitHandler(),
    autocomplete: 'one-time-code',
    shouldAutoFocus: true,
    step: 2,
  },
]
