import { useEffect, useState } from 'react'

const useValidatingForm = (formState) => {
  const [formIsValid, setFormIsValid] = useState(false)

  useEffect(() => {
    const { isValid, isDirty } = formState
    setFormIsValid(isDirty && isValid)
  }, [formState])

  return formIsValid
}

export default useValidatingForm
