import { connect, useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@tanstack/react-query'

import { routes } from '../../router/routes'
import { ADMIN_ROUTE_MENU, ROLE_GUEST } from '../../utils/constants'
import { getMyCompanies, switchAccountCompany } from '../../api/admin/company'
import { setRoleUser } from '../../redux/actions/user'
import { toggleDisplayAdminMenu } from '../../redux/actions/ui'
import { clearSession, updateToken } from '../../utils/auth'
import { Exit } from '../SvgIcon'
import OrganizationSwitcher from '../OrganizationSwitcher'
import NavigateLink from './components/NavigateLink'

import './styles.css'

const AdminMenu = ({ showMenu, toggleDisplayAdminMenu, userId, currentCompany }) => {
  const dispatch = useDispatch()

  const logoutAdmin = () => {
    clearSession().then(() => dispatch(setRoleUser(ROLE_GUEST)))
  }

  const { data: companies } = useQuery({
    queryKey: ['adminCompanies', userId, currentCompany.id],
    queryFn: getMyCompanies,
  })

  const mutationSwitchAdminCompany = useMutation({
    mutationKey: ['switchAdminCompany'],
    mutationFn: switchAccountCompany,
    onSuccess: async ({ data }) => {
      await updateToken(data?.newToken)
      window.location.reload()
    },
  })

  return (
    <div className={`navigationAdmin ${!showMenu ? 'hide' : ''}`}>
      <OrganizationSwitcher
        orgList={companies?.data}
        onSwitchOrg={mutationSwitchAdminCompany.mutate}
        selectedOrg={currentCompany}
        innerClassName="adminOrgSwitcher"
      />
      <div className="innerMenuAdmin">
        {ADMIN_ROUTE_MENU.map((item, index) => (
          <NavigateLink {...item} hideTooltip={showMenu} key={index} />
        ))}
      </div>
      <NavigateLink
        Icon={Exit}
        hideTooltip={showMenu}
        onClick={logoutAdmin}
        route={routes.adminLogin}
        text="Logout"
      />
      <button onClick={() => toggleDisplayAdminMenu()} className="btnCollapseAdminMenu">
        <div />
        <div />
      </button>
    </div>
  )
}

const mapStateToProps = ({ ui, user, currentCompany }) => ({
  currentCompany,
  userId: user.data.id,
  showMenu: ui.showAdminMenu,
})
const mapDispatchToProps = { toggleDisplayAdminMenu }

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu)
