import { useState } from 'react'
import { debounce, isEmpty } from 'lodash'
import { Dropdown } from 'rsuite'
import ReactPaginate from 'react-paginate'
import { useNavigate } from 'react-router-dom'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { routes } from '../../../router/routes'
import {
  DROPDOWN_DATA_GIFT_STATUS,
  SETTINGS_PAGINATION,
  TABLE_ROW_TYPE,
  TITLE_GIFT_COUPONS,
} from '../../../utils/constants'
import { getAllPaginateGiftPricing } from '../../../api/giftCoupons'
import { Analytics, Plus, ThreeDots } from '../../../components/SvgIcon'
import ModalGiftPricing from '../../../components/Modals/ModalGiftPricing'
import CustomDropdown from '../../../components/CustomDropdown'
import CustomTable from '../../../components/CustomTable'
import CustomInput from '../../../components/CustomInput'
import PrimaryButton from '../../../components/PrimaryButton'

import './styles.css'

const GiftPricing = () => {
  const [filterData, setFilterData] = useState({})
  const [selectedPage, setSelectedPage] = useState(1)
  const [showModalGiftPricing, setShowModalGiftPricing] = useState(false)
  const navigation = useNavigate()

  const { data: { data: giftsPricing } = {} } = useQuery({
    queryKey: ['allGiftsPricing', selectedPage, filterData],
    queryFn: () =>
      getAllPaginateGiftPricing({
        size: 8,
        page: selectedPage,
        ...filterData,
      }),
    placeholderData: keepPreviousData,
  })

  const handleChangeFilter = debounce((data) => {
    setSelectedPage(1)
    setFilterData((oldState) => ({ ...oldState, ...data }))
  }, 300)

  return (
    <div>
      <div className="innerDashboard">
        <div className="d-flex justify-content-between gap-2 align-items-center mb-3">
          <h1>Gift pricing</h1>
          <div className="d-flex align-items-center gap-1">
            <PrimaryButton
              Icon={Plus}
              text="Create gift"
              onClick={() => setShowModalGiftPricing(true)}
            />
            <Dropdown
              placement="bottomEnd"
              renderToggle={(props, ref) => <ThreeDots size={25} ref={ref} {...props} />}
            >
              <Dropdown.Item
                icon={<Analytics size={18} style={{ marginRight: 5 }} />}
                onClick={() => navigation(routes.adminGiftCouponsAnalytics)}
              >
                Analytics
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
        <div className="filterGiftPricing mb-4">
          <CustomInput
            placeholder={'Search by name, price...'}
            defaultValue=""
            innerClassName={'innerGiftPricingSearch'}
            onInput={(e) => handleChangeFilter({ searchBy: e.target?.value })}
          />
          <CustomDropdown
            title="All Gifts"
            valueKey="value"
            value={filterData?.active}
            data={DROPDOWN_DATA_GIFT_STATUS}
            onChange={(value) => handleChangeFilter({ active: value })}
          />
        </div>
        <CustomTable
          cellData={giftsPricing?.data}
          tableHeader={TITLE_GIFT_COUPONS}
          customTableClass="tableGiftPricing"
          type={TABLE_ROW_TYPE.TABLE_ROW_GIFT_PRICING}
          onClickEdit={(giftItem) => setShowModalGiftPricing(giftItem)}
        />
        {!isEmpty(giftsPricing?.data) && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <p className="totalResult">{giftsPricing.total} Result</p>
            <ReactPaginate
              {...SETTINGS_PAGINATION}
              pageCount={giftsPricing.lastPage}
              marginPagesDisplayed={-1}
              pageRangeDisplayed={-1}
              containerClassName="d-flex align-items-stretch gap-2"
              onPageChange={({ selected }) => setSelectedPage(selected + 1)}
            />
          </div>
        )}
      </div>

      <ModalGiftPricing show={showModalGiftPricing} onHide={() => setShowModalGiftPricing(false)} />
    </div>
  )
}

export default GiftPricing
