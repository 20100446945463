import { useForm } from 'react-hook-form'
import { Drawer } from 'rsuite'

import { INPUT_TYPES } from '../../../utils/constants'
import Button from '../../Button'
import PrimaryButton from '../../PrimaryButton'
import PreviewContent from '../../PreviewContent'
import ControllerField from '../../HookForm/components/ControllerField'

import './styles.css'

const ModalThemeContent = ({ show, onHide }) => {
  const { handleSubmit, control, watch, formState } = useForm()

  const onSubmit = () => {}

  return (
    <Drawer placement="right" open={show} className="modalThemeContent" onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title className="blockTitle mb-0 mt-1">Update content</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="p-3">
        <div className="d-flex h-100">
          <form className="formManageThemeContent" onSubmit={handleSubmit(onSubmit?.mutate)}>
            <ControllerField
              control={control}
              name="image"
              label="Image (required)"
              innerClassName=""
              type={INPUT_TYPES.INPUT_FILE}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            <ControllerField
              control={control}
              name="title"
              label="Title (required)"
              innerClassName="mt-3 mw-100 w-100"
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            <ControllerField
              control={control}
              name="artistName"
              label="VO artist (required)"
              innerClassName="mt-3 mw-100 w-100"
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            <ControllerField
              control={control}
              name="body"
              label="Body (required)"
              as="textarea"
              rows={6}
              innerClassName="mt-3"
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            <ControllerField
              control={control}
              name="audio"
              id="contentAudioUploader"
              // label="Body (required)"
              type={INPUT_TYPES.INPUT_UPLOAD_AUDIO}
              innerClassName="mt-3"
              defaultValue=""
              rules={{
                required: true,
              }}
            />
          </form>
          <div className="flex-grow-1">
            <PreviewContent
              title={watch('title') || 'Preview title'}
              body={watch('body') || 'Preview body'}
              bgImageUrl={watch('image')?.imgUrl}
              audioUrl={watch('audio')?.audioUrl}
            />
          </div>
        </div>
      </Drawer.Body>
      <Drawer.Actions className="drawerActionModalFooter">
        <Button onClick={onHide}>Cancel</Button>
        <PrimaryButton
          disabled={onSubmit.isPending || !formState.isValid || !formState.isDirty}
          onClick={handleSubmit(onSubmit.mutate)}
          text={'Save content'}
          //{'asd' === 'asd' ? 'Update theme' : 'Create theme'}
        />
      </Drawer.Actions>
    </Drawer>
  )
}

export default ModalThemeContent
