import { Tag } from 'rsuite'
import { useMemo } from 'react'

const CustomTag = ({ tagInfo, deleteTag, selected = false, ...rest }) => {
  const styles = useMemo(
    () =>
      selected
        ? {
            backgroundColor: tagInfo?.color,
            color: '#FFFFFF',
          }
        : {
            backgroundColor: 'transparent',
            color: tagInfo?.color,
          },
    [selected]
  )

  return (
    <Tag
      {...rest}
      closable={selected}
      size="lg"
      style={{ ...styles, fontWeight: 500, border: `1px solid ${tagInfo?.color}`, ...rest.style }}
      onClose={deleteTag}
    >
      {tagInfo?.name}
    </Tag>
  )
}

export default CustomTag
