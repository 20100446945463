import { isNil } from 'lodash'

import { getMe } from '../api/auth'
import { getReferences } from '../api/common'
import { getInfoCurrentCompany } from '../api/admin/company'

import { AUTH_TOKEN, ROLES } from './constants'
import { clearSession, updateToken } from './auth'
import localStorage from './localStorage'
import errorHandler from './errorHandler'
import r from './request'

const userInfo = async () => {
  try {
    return await getMe()
  } catch (error) {
    await clearSession()
    return null
  }
}

const autoload = async (store, navigate, websocketConnect) => {
  r.setHandlers(null, (e) => errorHandler(e, navigate, store.dispatch))
  let references = {}

  try {
    const token = await localStorage.getItem(AUTH_TOKEN)
    references = await getReferences()

    if (isNil(token)) {
      return { user: null, references }
    }

    await updateToken(token)
    const user = await userInfo()

    await websocketConnect()
    const currentCompany =
      user.data.role === ROLES.ROLE_ADMIN ? await getInfoCurrentCompany() : null

    return { user: user.data, references, currentCompany: currentCompany.data }
  } catch (e) {
    return { user: null, references, currentCompany: null }
  }
}

export default autoload
