import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Toggle, Tooltip, Whisper } from 'rsuite'

import { PIPELINE_TYPES, PRICE_TYPES } from '../../../../utils/constants'
import { getMessagesCount } from '../../../../api/admin/messages'
import { publishTheme, unpublishTheme } from '../../../../api/admin/themes'
import { WarningAmber } from '../../../../components/SvgIcon'

const ToggleActivateTheme = ({ isPublished, priceInfo }) => {
  const { themeId } = useParams()
  const queryClient = useQueryClient()

  const mutationPublishTheme = useMutation({
    mutationKey: ['publishTheme', themeId],
    mutationFn: () => publishTheme(themeId, { success: 'Theme activated' }),
    onSuccess: () =>
      queryClient.setQueryData(['themeInfo', themeId], ({ data }) => {
        return {
          data: { ...data, isPublished: true },
        }
      }),
  })
  const mutationUnpublishTheme = useMutation({
    mutationKey: ['unPublishTheme', themeId],
    mutationFn: () => unpublishTheme(themeId, { success: 'Theme activated' }),
    onSuccess: () =>
      queryClient.setQueryData(['themeInfo', themeId], ({ data }) => {
        return {
          data: { ...data, isPublished: false },
        }
      }),
  })

  const { data: countContentMessages } = useQuery({
    queryKey: ['countContentMessages', themeId],
    queryFn: () => getMessagesCount({ themeId, pipelineType: PIPELINE_TYPES.CONTENT }),
    enabled: priceInfo?.type === PRICE_TYPES.ONE_TIME,
  })

  return (
    <div className="d-flex align-items-start gap-2">
      {priceInfo?.type === PRICE_TYPES.ONE_TIME &&
      countContentMessages?.data < priceInfo?.extensionDays ? (
        <Whisper
          placement="top"
          controlId={themeId}
          trigger="hover"
          speaker={
            <Tooltip>
              When you created this <b>one-time plan</b>, you specified that it would contain{' '}
              <b>{priceInfo?.extensionDays}</b> messages. Currently, the number of messages in the
              application is <b>{countContentMessages?.data}</b>, which is less than you specified.
            </Tooltip>
          }
        >
          <div className="d-inline-block me-2">
            <WarningAmber className="jumpShakingAnimation" fill="var(--catalina-blue)" size={30} />
          </div>
        </Whisper>
      ) : null}
      <Toggle
        onChange={!isPublished ? mutationPublishTheme.mutate : mutationUnpublishTheme.mutate}
        size="lg"
        color="var(--catalina-blue)"
        loading={mutationUnpublishTheme.isPending || mutationPublishTheme.isPending}
        checked={isPublished}
        checkedChildren="Activated"
        unCheckedChildren="Deactivated"
      />
    </div>
  )
}

export default ToggleActivateTheme
