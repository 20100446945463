import { isEmpty } from 'lodash/lang'
import Form from 'react-bootstrap/Form'

import './style.css'

const CustomRadio = ({ buttons, label, value, onChange, innerClassName = '' }) => {
  return (
    <div className={innerClassName}>
      {isEmpty(label) && <p className="titleCountDays">{label}</p>}
      <div className="innerRadioButtons">
        {buttons.map(({ label, value: valueCheck, name }) => (
          <div key={valueCheck} className="innerRadioButton">
            <Form.Check.Input
              className="radioButton"
              name={name}
              type="radio"
              onChange={() => onChange(valueCheck)}
              value={valueCheck}
              id={valueCheck}
              checked={value === valueCheck}
              isValid
            />
            <Form.Check.Label htmlFor={valueCheck} className="labelRadioButton">
              {label}
            </Form.Check.Label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomRadio
