import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'

import { APP_ELEMENTS } from '../../utils/constants'
import { setShowEditorGlobalOnboardingSMS } from '../../redux/actions/ui'
import { LoadingIcon } from '../SvgIcon'
import ModalOnboardingMessage from '../Modals/ModalOnboardingMessage'
import AdminMenu from '../AdminMenu'
import Header from './components/Header'
import IncompleteSetupBanner from './components/IncompleteSetupBanner'

import './styles.css'

const LayoutAdmin = ({
  children,
  loadingInfo,
  showIncompleteSetupBanner,
  showEditorGlobalOnboardingSMS,
  setShowEditorGlobalOnboardingSMS,
}) => {
  return (
    <section data-incomplete-setup-banner={showIncompleteSetupBanner} className="adminLayout">
      <IncompleteSetupBanner show={showIncompleteSetupBanner} />
      <Helmet title={`${APP_ELEMENTS.NAME}: Admin Panel`} />
      <AdminMenu />
      <div className="innerInfoSection flex-grow-1 position-relative mw-100">
        <Header />
        <div className="innerAdminPages">
          {children ?? <Outlet />}
          {loadingInfo && (
            <div className="innerLoadingAdmin">
              <LoadingIcon className="loadingAdmin" />
            </div>
          )}
        </div>
      </div>
      <ModalOnboardingMessage
        show={showEditorGlobalOnboardingSMS}
        onHide={() => setShowEditorGlobalOnboardingSMS(false)}
      />
    </section>
  )
}

const mapStateToProps = ({ ui, currentCompany }) => ({
  loadingInfo: ui.loadingAdminDataProject,
  showEditorGlobalOnboardingSMS: ui.showEditorGlobalOnboardingSMS,
  showIncompleteSetupBanner: currentCompany.profileProgress < 100,
})
const mapDispatchToProps = {
  setShowEditorGlobalOnboardingSMS,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdmin)
