import React from 'react'
import { isEmpty } from 'lodash'
import {
  Bar,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LineChart,
  Tooltip,
  Customized,
  Text,
} from 'recharts'

import {
  DOT_STYLE,
  DEFAULT_SETTINGS_XAxis,
  DEFAULT_SETTINGS_LINE_CHART,
} from '../../utils/constants'

const ChartProgression = ({ data }) => {
  const renderCustomTooltip = ({ active, payload }) => {
    if (!active || !payload || payload.length === 0) {
      return null
    }

    const tooltipData = payload[0].payload

    return (
      <div className="bg-light p-1 ps-2 pe-2 border border-dark rounded">
        <p>{tooltipData.value}</p>
      </div>
    )
  }

  return (
    <ResponsiveContainer className="wrapperChart" height={300} width="100%">
      <LineChart {...DEFAULT_SETTINGS_LINE_CHART} data={data?.dataChart}>
        <CartesianGrid vertical={false} />
        <XAxis {...DEFAULT_SETTINGS_XAxis} />
        <YAxis interval={0} allowDecimals={false} tickSize={0} tickMargin={15} axisLine={false} />

        <Tooltip content={renderCustomTooltip} />

        {data?.dataLines?.map((item, index) => (
          <React.Fragment key={index}>
            <Line dot={DOT_STYLE} dataKey={item.name} stroke={item.color} strokeWidth={4} />
            <Bar dataKey={item.name} fill={item.color} />
          </React.Fragment>
        ))}

        <Legend />
        <Customized
          component={() => {
            return isEmpty(data) ? (
              <Text
                style={{ transform: `translate(50%, 50%)` }}
                x={0}
                textAnchor="middle"
                verticalAnchor="middle"
              >
                No data available
              </Text>
            ) : null
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default ChartProgression
