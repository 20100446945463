import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { MultiBackend, DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { HelmetProvider } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import 'rsuite/dist/rsuite.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'

import autoload from './utils/autoload'
import createStore from './redux/store'
import useWebSocket from './utils/hooks/useWebSocket'
import LoadingContainer from './components/LoadingContainer'

import Router from './router'
import './App.css'

function App() {
  const [store, setStore] = useState(createStore())
  const navigate = useNavigate()
  const [isLoadingApp, setIsLoadingApp] = useState(true)
  const { connect, websocket } = useWebSocket()

  useEffect(() => {
    autoload(store, navigate, connect)
      .then(({ user, references, currentCompany }) =>
        setStore(createStore(user, references, currentCompany))
      )
      .finally(() => setIsLoadingApp(false))

    return () => websocket?.close()
  }, [])

  return (
    <div className="App">
      <HelmetProvider>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <Provider store={store}>
            <Router isLoadingApp={isLoadingApp} />
            <LoadingContainer isLoadingApp={isLoadingApp} />
          </Provider>
        </DndProvider>
      </HelmetProvider>
    </div>
  )
}

export default App
