import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import userReducer from './reducers/user'
import uiReducer from './reducers/ui'
import referencesReducer from './reducers/references'
import currentCompanyReducer from './reducers/currentCompany'

export const createStore = (user, references, currentCompany) => {
  const reducers = {
    ui: uiReducer(),
    user: userReducer(user),
    references: referencesReducer(references),
    currentCompany: currentCompanyReducer(currentCompany),
  }

  const rootReducer = combineReducers(reducers)
  return configureStore({ reducer: rootReducer })
}

export default createStore
