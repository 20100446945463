import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isNil, map } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Drawer } from 'rsuite'

import { DISCOUNT_TYPES } from '../../../utils/constants'
import { FIELDS_GIFT_COUPONS } from '../../../utils/constantsForms'
import {
  changeGiftCoupon,
  createGiftCoupon,
  getAvailableStripeCoupons,
  getPricingInfo,
} from '../../../api/giftCoupons'
import HookFormFieldsArray from '../../HookForm/HookFormFieldsArray'
import PreviewGiftPrice from './components/PreviewGiftPrice'

import './styles.css'

const ModalGiftPricing = ({ show, onHide }) => {
  const [availableStripeCoupons, setAvailableStripeCoupons] = useState([true])
  const formRef = useRef(null)
  const queryClient = useQueryClient()
  const isChangeMode = useMemo(() => !isNil(show?.id), [show])

  const onSuccessMutation = () => {
    onHide()
    queryClient.invalidateQueries({ queryKey: ['allGiftsPricing'] })
  }

  const mutationCreateGiftCoupon = useMutation({
    mutationKey: ['createGiftPricing'],
    mutationFn: (formData) => createGiftCoupon({ ...formData, image: formData.image?.file }),
    onSuccess: onSuccessMutation,
  })
  const mutationChangeGiftCoupon = useMutation({
    mutationKey: ['changeGiftPricing', show?.id],
    mutationFn: (formData) => changeGiftCoupon({ ...formData, image: formData.image?.file }),
    onSuccess: onSuccessMutation,
  })

  const submitFunction = useCallback(
    isChangeMode ? mutationChangeGiftCoupon.mutate : mutationCreateGiftCoupon.mutate,
    [show]
  )

  useEffect(() => {
    if (isChangeMode) {
      getPricingInfo(show?.id).then(({ data }) => {
        formRef.current.update(3, { ...FIELDS_GIFT_COUPONS[3], disabled: true })
        formRef.current.setValue('image', data?.photoUrl)
        map(data, (value, key) => formRef.current.setValue(key, value))
      })
    }
  }, [show])

  useEffect(() => {
    if (show) {
      getAvailableStripeCoupons().then(({ data }) => {
        setAvailableStripeCoupons(data.reduce((acc, val) => ({ ...acc, [val.id]: val }), {}))
        const formattedData = data.map(({ discountAmount, discountType, name, ...rest }) => {
          const discountLabel =
            DISCOUNT_TYPES.PERCENT === discountType
              ? `Discount -${discountAmount}% on "${name}"`
              : `Discount -${discountAmount}$ on "${name}"`
          return { ...rest, discountLabel }
        })
        formRef.current.update(4, {
          ...FIELDS_GIFT_COUPONS[4],
          data: [{ discountLabel: 'Discount: 0%', id: null }, ...formattedData],
        })
      })
    }
  }, [show])

  return (
    <Drawer placement="right" open={show} size="md" onClose={onHide}>
      <Drawer.Header closeButton>
        <Drawer.Title>Gift Pricing</Drawer.Title>
        <Drawer.Actions>
          <Button
            appearance="primary"
            style={{ backgroundColor: 'var(--catalina-blue)' }}
            onClick={() => formRef.current.handleSubmit(submitFunction)()}
          >
            Save
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body className="p-0">
        <HookFormFieldsArray
          ref={formRef}
          defaultFields={FIELDS_GIFT_COUPONS}
          onSubmit={submitFunction}
          globalName="giftCoupon"
          globalFormClassName="d-flex h-100"
          className="formManageGiftPricing p-4"
          appendComponent={({ watch }) => (
            <PreviewGiftPrice
              monthlyPrice={watch('price')}
              discountInfo={availableStripeCoupons?.[watch('stripeCouponId')]}
            />
          )}
        />
      </Drawer.Body>
    </Drawer>
  )
}

export default ModalGiftPricing
