import { connect } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

import { ROLES } from '../../utils/constants'
import { getMyCompanies } from '../../api/admin/company'
import OrgCard from './components/OrgCard'

import './styles.css'

const SwitchOrganization = ({ userId, userRole, currentCompanyId }) => {
  const { data: adminCompanies } = useQuery({
    queryKey: ['adminCompanies', userId],
    queryFn: getMyCompanies,
    enabled: userRole === ROLES.ROLE_ADMIN,
  })

  return (
    <div className="switchOrganizationList">
      {adminCompanies?.data.map(({ id, name, smallLogo, phone }) => (
        <OrgCard
          key={id}
          companyId={id}
          name={name}
          logo={smallLogo}
          twilioPhoneNumber={phone}
          isCurrent={id === currentCompanyId}
        />
      ))}
    </div>
  )
}

const mapStateToProps = ({ user, currentCompany }) => ({
  userRole: user.role,
  userId: user.data.id,
  currentCompanyId: currentCompany.id,
})

export default connect(mapStateToProps)(SwitchOrganization)
