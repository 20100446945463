import { handleActions } from 'redux-actions'

import {
  setLoadingApp,
  setLoadingUserContent,
  setModalErrorData,
  setModalShareConfig,
  setShowModalSupport,
  setLoadingAdminProjectData,
  setShowModalCancelSubscription,
  toggleDisplayUserMenu,
  toggleDisplayAdminMenu,
  displayAdminMenu,
  setShowEditorGlobalOnboardingSMS,
} from '../actions/ui'

const initialState = {
  loadingApp: false,
  modalShareConfig: {},
  showModalSupport: false,
  loadingAdminDataProject: false,
  showModalCancelSubscription: false,
  showAdminMenu: true,
  showUserMenu: localStorage.getItem('SHOW_USER_MENU'),
  showLoadingUserContent: false,
  showEditorGlobalOnboardingSMS: false,
  modalErrorData: { show: false, errorStatus: '', errorText: '' },
}

const actions = {
  [setLoadingAdminProjectData]: (state, { payload }) => {
    return {
      ...state,
      loadingAdminDataProject: payload,
    }
  },
  [setLoadingApp]: (state, { payload }) => {
    return {
      ...state,
      loadingApp: payload,
    }
  },
  [setLoadingUserContent]: (state, { payload }) => {
    return {
      ...state,
      showLoadingUserContent: payload,
    }
  },
  [setModalErrorData]: (state, { payload }) => {
    return {
      ...state,
      modalErrorData: payload,
    }
  },
  [setModalShareConfig]: (state, { payload }) => {
    return {
      ...state,
      modalShareConfig: { ...state.modalShareConfig, ...payload },
    }
  },
  [setShowModalSupport]: (state, { payload }) => {
    return {
      ...state,
      showModalSupport: payload,
    }
  },
  [setShowModalCancelSubscription]: (state, { payload }) => {
    return {
      ...state,
      showModalCancelSubscription: payload,
    }
  },
  [toggleDisplayAdminMenu]: (state) => {
    return {
      ...state,
      showAdminMenu: !state.showAdminMenu,
    }
  },
  [toggleDisplayUserMenu]: (state) => {
    return {
      ...state,
      showUserMenu: !state.showUserMenu,
    }
  },
  [displayAdminMenu]: (state, { payload }) => {
    return {
      ...state,
      showAdminMenu: payload,
    }
  },
  [setShowEditorGlobalOnboardingSMS]: (state, { payload }) => {
    return {
      ...state,
      showEditorGlobalOnboardingSMS: payload,
    }
  },
}

const createReducer = (isLoading = true) => {
  return handleActions(actions, { ...initialState, isLoading })
}

export default createReducer
