import { useState } from 'react'

import { ArrowDown } from '../SvgIcon'
import ImageLoader from '../ImageLoader'

import './styles.css'

const OrganizationSwitcher = ({ orgList, onSwitchOrg, selectedOrg, innerClassName = '' }) => {
  const [showOrganizationList, setShowOrganizationList] = useState(false)

  return (
    <div className={`innerOrganizationSwitcher ${innerClassName}`}>
      <button
        className="btnSwitcher"
        onClick={() => setShowOrganizationList((oldState) => !oldState)}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowOrganizationList(false)
          }
        }}
      >
        <ImageLoader
          src={selectedOrg?.smallLogo}
          loadingProps={{ size: 15 }}
          alt={`Logo ${selectedOrg?.name}`}
          imageClassName="smallLogo"
        />
        <span className="organizationName">{selectedOrg?.name}</span>
        {orgList?.length > 1 && <ArrowDown className="arrow" size={22} style={{ minWidth: 22 }} />}
      </button>
      <ul
        data-show-list={showOrganizationList}
        className={`organizationList ${orgList?.length > 1 ? '' : 'd-none'}`}
      >
        {orgList?.map(({ id, name, smallLogo }) => (
          <li
            onClick={() => {
              if (selectedOrg?.id !== id) {
                onSwitchOrg(id)
                setShowOrganizationList(false)
              }
            }}
            onMouseDown={(e) => e.preventDefault()} // Prevent the blur event
            key={id}
            aria-checked={selectedOrg?.id === id}
            className="organizationListItem"
          >
            <ImageLoader
              src={smallLogo}
              loadingProps={{ size: 15 }}
              alt={`Logo ${name}`}
              imageClassName="smallLogo"
            />
            <span className="organizationName">{name}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default OrganizationSwitcher
