import { useState } from 'react'
import { connect } from 'react-redux'

import TabDetails from './components/TabDetails'
import TabInterests from './components/TabInterests'

import './styles.css'

const AccountInformation = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <div className="accountDetails">
      <h1 className="titleProfileSecond">Account</h1>
      <div className="tabsList mt-3 justify-content-start">
        {['Details', 'Interests'].map((tabName, index) => (
          <p
            key={tabName}
            onClick={() => setActiveTabIndex(index)}
            className={`tab ${index === activeTabIndex ? 'active' : ''}`}
          >
            {tabName}
          </p>
        ))}
      </div>
      <TabDetails isActiveBlock={activeTabIndex === 0} />
      <TabInterests isActiveBlock={activeTabIndex === 1} />
    </div>
  )
}

export default connect(({ user }) => ({ userPhone: user.data.phone }))(AccountInformation)
