import { Table } from 'rsuite'
import ImageLoader from '../../ImageLoader'

const ImageCell = ({ rowData, dataKey, imageProps, ...props }) => (
  <Table.Cell {...props}>
    <ImageLoader
      innerClassName="tableImageCell"
      src={rowData?.[dataKey]}
      alt={`table-image-${dataKey}`}
      {...imageProps}
    />
  </Table.Cell>
)

export default ImageCell
